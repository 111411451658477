<template>
  <section id="work" class="space-200 abt-sec backtest">
    <div class="container">

        <div class="heading text-center">
          <div class="content">
          <h3>Our <span>Partners</span></h3>
          </div>

          <div class="bottom-text"></div>
          <carousel
                  :controls="false"
                  :interval="3000"
                  :loop="true"
                  :indicators="false"
                  :autoplay="true"
                  :paginationPadding="10"
                  :perPageCustom="[[367, 1],[768, 3] ,[1024, 1], [1400, 2]]"
                  :spacePadding='10'
                  >
            <slide
                    v-for="item in items"
                    :key="item.id"
                    class=" " >


              <div class="img-container" >

                <img  v-if="!isMobile" :style="{ width: item.size  + 'px' }"
                        :src="item.img"
                        :alt="item.tags">
                <img  v-else :style="{ width: item.size -60 + 'px' }"
                      :src="item.img"
                      :alt="item.tags">
              </div>

            </slide>

          </carousel>

        </div>
    </div>

  </section>
</template>

<script>
export default {
  name: 'vendors_1',
  data () {
    return {
      items: [
         {
          img: require('../assets/img/vender/crestron.png'),
          tags: ['AMX', 'V-Signage', 'vCore'],
          size: 500,

        },
        {
          img: require('../assets/img/vender/2499px-AMX_logo.svg.png'),
          tags: ['AMX', 'V-Signage', 'vCore'],
          size: 300,

        },
        {
          img: require('../assets/img/vender/Biamp.png'),
          tags: ['Biamp', 'V-Signage', 'vCore'],
          size: 300,

        },
        {
          img: require('../assets/img/vender/cisco.png'),
          size: 300,
          tags: ['Cisco', 'Media'],

        },
        {
          img: require('../assets/img/vender/extron.png'),
          tags: ['Extron', 'LED', 'Qflex'],
          size: 300,

        },
        {
          img: require('../assets/img/vender/logitech.png'),
          tags: ['Logutech', 'Sound', ''],
          size: 300,

        },
        {
          img: require('../assets/img/vender/newtek_w.png'),
          tags: ['Newtek', 'Newtek'],
          size: 300,

        },

        {
          img: require('../assets/img/vender/shure.png'),
          size: 300,
          tags: ['Shure', 'Shure'],

        },
        {
          img: require('../assets/img/vender/sony.png'),
          size: 300,
          tags: ['Sony', 'Sony'],

        },
        {
          img: require('../assets/img/vender/tannoy.png'),
          size: 300,
          tags: ['Tannoy', 'Media'],

        },
        {
          img: require('../assets/img/vender/yam.png'),
          size: 300,
          tags: ['Yamaha', 'Yamaha'],

        },
        {
          img: require('../assets/img/vender/MaxHub-White-Logo.png'),
          size: 300,
          tags: ['Maxhub', 'Media'],

        },
        {
          img: require('../assets/img/vender/panasonic-logo-2.png'),
          size: 300,
          tags: ['Panasonic', 'Panasonic'],

        },
        {
          img: require('../assets/img/vender/qsc.png'),
          size: 300,
          tags: ['qsc', 'Media'],

        },
        {
          img: require('../assets/img/vender/samsung.png'),
          size: 300,
          tags: ['Samsung', 'Media'],

        },


      ]

    }
  },
  mounted: function () {

  },
  methods: {
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style scoped>
  .abt-sec h3:after {
    content: '';
    width: 0px;
    height: 3px;
    background: #05a4db;
    display: block;

  }
  .VueCarousel-slide {
    height: 220px;
    text-align: center;
  }

  .VueCarousel-slide .img-container {
    height: 130px;
    width: 100%;
    float: left;
  }

  .VueCarousel-slide img {
    margin: 0 auto;
    width: 200px;
    padding-top: 40px;
  }



  @media only screen and (min-width: 1600px) {
    .abt-sec .content {
      width: 545px;
      margin: 0px auto;
    }
  }
  .line.white:after {
    border-color: #c1c1c1;
  }
  .carousel {
    /* any dimensions are fine, it can be responsive with max-width */
    width: 300px;
    height: 200px;
  }
.backtest{
  background-attachment: scroll, fixed;
  padding-bottom: 30px;
  padding-top: 30px;
  background-color: rgba(163, 171, 176, 0.17);
background-size: cover;


}
.sl_item{
  margin: 25px 5px;
}
.Vue-Carousel-slide
  {
    max-width: 95%;
  }
  .item {
    background-size: cover;
    background-position: 50% 50%;
    width: 100%;
    height: 100%;
  }

  .item img {
    visibility: hidden;
  }


</style>
