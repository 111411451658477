<template>
  <section id="work" class="space-200 abt-sec backtest">
    <div class="container">

        <div class="heading text-center">
          <div class="content">
          <h3>Our <span>Clients</span></h3>
          </div>

          <div class="bottom-text"></div>
          <carousel
                  :controls="false"
                  :interval="3000"
                  :loop="true"
                  :indicators="false"
                  :autoplay="true"
                  :paginationPadding="10"
                  :perPageCustom="[[367, 1],[768, 2] ,[1024, 1], [1400, 2]]"
                  :spacePadding='50'
                  >
            <slide
                    v-for="item in items"
                    :key="item.id"
                    class=" " >


              <div class="img-container" >

                <img  v-if="!isMobile" :style="{ width: item.size  + 'px' }"
                        :src="item.img"
                        :alt="item.tags">
                <img  v-else :style="{ width: item.size -60 + 'px' }"
                      :src="item.img"
                      :alt="item.tags">
              </div>

            </slide>

          </carousel>

        </div>
    </div>

  </section>
</template>

<script>
export default {
  name: 'clients_1',
  data () {
    return {
      items: [ 
      {
          title: 'Engineers Australia',
          img: require('../assets/img/YEA-logo.webp'),
          tags: ['V-Wall', 'V-Signage', 'vCore'],
          size: 400,
          des: '',
          page: ''
        },
      {
          title: 'North Metro',
          img: require('../assets/img/NMHS Multi Coloured NM.png'),
          tags: ['V-Wall', 'V-Signage', 'vCore'],
          size: 570,
          des: '',
          page: ''
        },
      {
          title: 'BGE',
          img: require('../assets/img/BGE-Resources-Logo-White.png'),
          tags: ['V-Wall', 'V-Signage', 'vCore'],
          size: 200,
          des: '',
          page: ''
        },
      {
          title: 'SRG',
          img: require('../assets/img/SRGG-logo.png'),
          tags: ['V-Wall', 'V-Signage', 'vCore'],
          size: 300,
          des: '',
          page: ''
        },
         {
          title: 'Curtin University',
          img: require('../assets/img/Curtin_0.png'),
          tags: ['Audio Visual', 'Sound', 'Lighting'],
          size: 610,
          des: 'Vidtiser teamed up with Ellenbrook arts/Ellenbrook Central/City of Swan/LWP and Prime projects to help the engage the public through V-wall and V-touch solutions',
          page: '/blog2'
        }, 
        {
          title: 'Funlabs',
          img: require('../assets/img/funlab_logo_2020_white.png'),
          tags: ['Audio Visual', 'Sound', 'Lighting'],
          size: 450,
          des: 'Vidtiser teamed up with Ellenbrook arts/Ellenbrook Central/City of Swan/LWP and Prime projects to help the engage the public through V-wall and V-touch solutions',
          page: '/blog2'
        },
       {
          title: 'BOM',
          img: require('../assets/img/BOM.png'),
          tags: ['V-Wall', 'V-Signage', 'V-Connect'],
          size: 500,
          des: 'Vidtiser teamed up with Ellenbrook arts/Ellenbrook Central/City of Swan/LWP and Prime projects to help the engage the public through V-wall and V-touch solutions',
          page: '/blog2'
        },
         {
          title: 'Subi Church',
          img: require('../assets/img/Subi_Church_Logo.png'),
          tags: ['V-Wall', 'V-Signage', 'V-Connect'],
          size: 400,
          des: 'Vidtiser teamed up with Ellenbrook arts/Ellenbrook Central/City of Swan/LWP and Prime projects to help the engage the public through V-wall and V-touch solutions',
          page: '/blog2'
        },
               {
          title: 'bank of china',
          img: require('../assets/img/Bank_Of_China_logo.png'),
          tags: ['V-Wall', 'V-Signage', 'V-Connect'],
          size: 400,
          des: 'Vidtiser teamed up with Ellenbrook arts/Ellenbrook Central/City of Swan/LWP and Prime projects to help the engage the public through V-wall and V-touch solutions',
          page: '/blog2'
        },
        {
          title: 'Ellenbrook Arts',
          img: require('../assets/img/chevron.webp'),
          tags: ['V-Wall', 'V-Signage', 'V-Connect'],
          size: 200,
          des: 'Vidtiser teamed up with Ellenbrook arts/Ellenbrook Central/City of Swan/LWP and Prime projects to help the engage the public through V-wall and V-touch solutions',
          page: '/blog2'
        },
        {
          title: 'Digital Menu Board',
          img: require('../assets/img/south32.png'),
          size: 400,
          tags: ['Digital signage', 'Media'],
          des: 'Runchy needed a great content management system and content for their menu boards. Vidtiser supplied and installed a complete solution that was ready to go as soon as they were',
          page: ''
        },
        {
          title: 'Yagan Square',
          img: require('../assets/img/cityofperthlogo.png'),
          tags: ['Signage', 'LED', 'Qflex'],
          size: 200,
          des: 'Vidtiser was contracted to do the tower sound and the live production system. The new Qflex speakers light up the ultramodern visual feature with world-class sound.',
          page: '/blog1'
        },
        {
          title: 'North Bridge Piazza',
          img: require('../assets/img/DevelopmentWA_Logo_Horiz.png'),
          tags: ['LED', 'Sound', 'Software'],
          size: 400,
          des: 'Vidtiser was contracted to redesign the playback system and consult on the LED video wall at the entertainment hotspot, improving the capabilities and reliability of an already outstanding venue',
          page: ''
        },
       

        {
          title: 'Digital Menu Board',
          img: require('../assets/img/arts.webp'),
          size: 300,
          tags: ['Digital signage', 'Media'],
          des: 'Runchy needed a great content management system and content for their menu boards. Vidtiser supplied and installed a complete solution that was ready to go as soon as they were',
          page: ''
        },
        {
          title: 'Digital Menu Board',
          img: require('../assets/img/megara-logo.png'),
          size: 300,
          tags: ['Digital signage', 'Media'],
          des: 'Runchy needed a great content management system and content for their menu boards. Vidtiser supplied and installed a complete solution that was ready to go as soon as they were',
          page: ''
        },
        {
          title: 'Digital Menu Board',
          img: require('../assets/img/renascent.png'),
          size: 400,
          tags: ['Digital signage', 'Media'],
          des: 'Runchy needed a great content management system and content for their menu boards. Vidtiser supplied and installed a complete solution that was ready to go as soon as they were',
          page: ''
        },
        {
          title: 'Digital Menu Board',
          img: require('../assets/img/state_library.png'),
          size: 400,
          tags: ['Digital signage', 'Media'],
          des: 'Runchy needed a great content management system and content for their menu boards. Vidtiser supplied and installed a complete solution that was ready to go as soon as they were',
          page: ''
        },
        {
          title: 'Digital Menu Board',
          img: require('../assets/img/cp_logo.png'),
          size: 400,
          tags: ['Digital signage', 'Media'],
          des: 'Runchy needed a great content management system and content for their menu boards. Vidtiser supplied and installed a complete solution that was ready to go as soon as they were',
          page: ''
        },
        {
          title: 'Digital Menu Board',
          img: require('../assets/img/The_rocks_logo.png'),
          size: 250,
          tags: ['Digital signage', 'Media'],
          des: 'Runchy needed a great content management system and content for their menu boards. Vidtiser supplied and installed a complete solution that was ready to go as soon as they were',
          page: ''
        },
      ]
    }
  },
  mounted: function () {

  },
  methods: {
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style scoped>
  .abt-sec h3:after {
    content: '';
    width: 0px;
    height: 3px;
    background: #05a4db;
    display: block;

  }
  .VueCarousel-slide {
    height: 220px;
    text-align: center;
  }

  .VueCarousel-slide .img-container {
    height: 130px;
    width: 100%;
    float: left;
  }

  .VueCarousel-slide img {
    margin: 0 auto;
    width: 200px;
    padding-top: 40px;
  }



  @media only screen and (min-width: 1600px) {
    .abt-sec .content {
      width: 545px;
      margin: 0px auto;
    }
  }
  .line.white:after {
    border-color: #c1c1c1;
  }
  .carousel {
    /* any dimensions are fine, it can be responsive with max-width */
    width: 300px;
    height: 200px;
  }
.backtest{
  background-attachment: scroll, fixed;
  padding-bottom: 30px;
  padding-top: 30px;
  background-color: rgba(163, 171, 176, 0.17);
background-size: cover;


}
.sl_item{
  margin: 25px 5px;
}
.Vue-Carousel-slide
  {
    max-width: 95%;
  }
  .item {
    background-size: cover;
    background-position: 50% 50%;
    width: 100%;
    height: 100%;
  }

  .item img {
    visibility: hidden;
  }


</style>
