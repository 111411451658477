<template>
  <div id="app">
    <login-menu></login-menu>
 <mainnav></mainnav>
    <transition name="router-anim" enter-active-class="animated fadein" leave-active-class="animated fadeOut">
    <router-view/>
    </transition>
    <contactform></contactform>
  <mfooter></mfooter>

  </div>
</template>

<script>

import Contactform from './components/contactform'
import Mfooter from './components/mfooter'
import Mainnav from './components/menu'
import LoginMenu from './components/login-menu'

export default {
  name: 'app',
  components: {LoginMenu, Mainnav, Mfooter, Contactform},
  data () {
    return {
      img1: require('./assets/img/arrow-left.png'),
      img2: require('./assets/img/arrow-right.png')
    }
  },
  methods: {

  },
  mounted: function () {


    $('.selection-2').select2({
      minimumResultsForSearch: 20,
      dropdownParent: $('#dropDownSelect1')
    });
    $('.loginPop').click(function (event) {
      event.preventDefault();
      $('.navigation').removeClass('active');
      $('.mb-toggler').removeClass('open');
      $('.login-sec').addClass('active');
      $('body').css('overflow', 'hidden');
    });
    $('.closeLogin').click(function () {
      $('.login-sec').removeClass('active');
      $('body').css('overflow', 'scroll')
    })
  }
}
</script>

<style>
    .fade-enter-active, .fade-leave-active {
        transition: opacity .2s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
#app {

}
@import "https://cdn.jsdelivr.net/npm/animate.css@3.5.1";

</style>
