<!-- eslint-disable vue/no-parsing-error -->
/* eslint-disable */
<template>
  <div class="landing">
  <section id="banner">
    <h2>V Media</h2>
    <p>Vidtisers Digital Media Creation</p>
  </section>

  <section id="main" class="container">

    <section class="box special">
      <header class="major">
        <h2>Embrace the future of digital signage </h2>
        <p>By embracing and implementing this type of digital signage delivery, you are leveraging your digital signage player investment and embracing the path of content delivery of the future.  </p>
      </header>
      <span class="image featured1"><img src="../assets/img/616902766.jpg" alt="" /></span>
    </section>

    <section class="box special features">
      <div class="features-row">
        <section>
          <span class="image featured" > <img src="../assets/img/Digital-Sigange-cms.png" alt="" />
        </span>
          <h3>Digital Signage Content Creation</h3>
          // eslint-disable-next-line vue/no-parsing-error </br>
          <p>Every medium used to convey information needs engaging content. Digital signage is no different. From the words used to the font chosen, digital signage has certain nuances and size constrictions that need be considered to achieve a successful campaign.
            Vidtiser offers Media content creation for all types of services.</p>

        </section>
        <section>
          <span class="image featured"><img src="../assets/img/media1.png" alt="" /></span>
          <h3>Artistic Content Creation</h3>
          </br>
          <p>Successful content is driven by a careful balance between art and science. On the art side of the scale, you need to apply psychology and creativity to develop a story that engages people.
            From large to small Vidtiser can create an art piece on any size display</p>

        </section>
        
      </div>
      </section>

  </section>

  </div>
</template>

<script>
import 'video.js/dist/video-js.css'


export default {
  name: 'VSignage',
  data () {
    return {
      playerOptions: {
        // videojs options
        muted: true,
        height: '300',
        overrideNative: true,
        playsinline: true,
        width: '480',
        loop: true,
        language: 'en',
        autoplay: true,
        sources: [{
          type: 'video/webm',
          src: '../static/buger.webm'
        }],
        poster: '../static/ser-img1.jpg'
      }
    }
  },
  components: {

  },
  mounted () {

  }
}
</script>

<style scoped>

@import url("../assets/css/mainservice.css");
#banner {
  background-attachment: scroll,							fixed;
  background-color: #666;
  background-image: url("../assets/img/overlay.png"), url("../assets/img/media3.jpg");
  background-position: top left,						center center;
  background-repeat: repeat,							no-repeat;
  background-size: auto,							cover;
  color: #fff;
  padding: 12em 0 20em 0;
  text-align: center;
}

.box .image.featured1 img {
  border-radius: 0;
  display: block;
  width: 100%;
  padding-top: 10px;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.27), 0 0 40px rgba(0, 0, 0, 0.06) inset;
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.27), 0 0 40px rgba(0, 0, 0, 0.06) inset;
}
@media screen and (max-width: 480px){
  .box .image.featured img {
    border-radius: 0 !important;
    height: 220px;
  }
}

</style>
