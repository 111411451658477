<template>
  <div class="landing">
  <section id="banner">
    <h2>vCore</h2>
    <p>Vidtisers Software Solutions</p>
  </section>

  <section id="main" class="container">

    <section class="box special">
      <header class="major">
        <h2>Creative Intelligence</h2>
        <p>Adding creative intelligence to impressive physical features is a sound strategy for leaving a lasting impression. </p>
      </header>
      <span class="image featured"><img src="../assets/img/productssoftware.jpg" alt="" /></span>
    </section>

    <section class="box special features">
      <div class="features-row">
        <section>
          <span class="image featured"><img src="../assets/img/touchsm.jpg" alt="" /></span>
          <h3>Software Solutions</h3>
          <br/>
          <p>Web Application - Data Management – Management Software - Smart Application Software. We develop software to help maximize productivity and profitability.</p>
          <p>Vidtiser offers solutions and software driven application to add more impact to your application.</p>
          <br />
          <div class="row centered" style=" margin: auto;padding: 0 0 0 3.3em;">


          </div>


        </section>
        <section>
          <span class="image featured"><img src="../assets/img/TouchScreenKiosk.jpg" alt="" /></span>
          <h3>Touch Screen Kiosk</h3>
          <br/>
          <p>Interactive displays and applications alleviate apathy and drive engagement through personal choice and direct feedback. Vidtiser can tailor programs and physical technology to your unique requirements for memorable experiences. </p>
          <br />
          <div class="row centered" style=" margin: auto;padding: 0 0 0 3.3em">


          </div>

        </section>
      </div>
      <div class="features-row">
        <section>
          <span class="image featured"><img src="../assets/img/artificial.jpg" alt="" /></span>
          <h3>Smart Display Signage</h3>
          <br/>
          <p>Smart digital signage, which combines facial detection software and anonymous analytics, is a tool that enables retailers to engage customers and provide an extra personal touch.</p>
          <br/>
          <br />


        </section>
        <section>
          <span class="image featured"><img src="../assets/img/test-automation-for-iot-apps.jpg" alt="" /></span>
          <h3>Internet of things</h3>
          <br/>
          <p>For many companies, the internet of things has suddenly become the thing: a techno-competitive mega-trend that can no longer be ignored</p>
          <p>Let the team at Vidtiser help your organization get ahead with the power of  internet of things. </p>
          <br/>
          <br />


        </section>
      </div>

    </section>



  </section>
  </div>
</template>

<script>
import { event } from 'vue-gtag'
export default {
  name: 'vCore',
  mounted () {
    event('Vcore', { method: 'Google' })
  }
}
</script>

<style scoped>

@import url("../assets/css/mainservice.css");
#banner {
  background-attachment: scroll,							fixed;
  background-color: #666;
  background-image: url("../assets/img/overlay.png"), url("../assets/img/7.jpg");
  background-position: top left,						center center;
  background-repeat: repeat,							no-repeat;
  background-size: auto,							cover;
  color: #fff;
  padding: 12em 0 20em 0;
  text-align: center;
}
@media screen and (max-width: 480px){
  .box .image.featured img {
    border-radius: 0 !important;
    height: 220px;
  }
}
</style>
