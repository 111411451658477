<template>
  <section id="login-form" class="login-sec">
    <div class="container">
      <div class="row">
        <div class="login-form-wrap">
          <div class="closeLogin">
            <i class="fa fa-close"></i>
          </div>
          <!-- /.closeLogin -->
          <h1 class="sec_title white-clr ta-l">Login</h1>
          <form action="">
            <div class="form-group">
              <label for="">Email</label>
              <input type="email" name="" value="" placeholder="Email">
            </div>
            <div class="form-group">
              <label for="">Password</label>
              <input type="password" name="" value="" placeholder="Password">
            </div>
            <div class="form-group">
              <a href="#" class="forget-link">Forgot Password ?</a>
              <input type="submit" name="" value="Login">
            </div>
          </form>
        </div>
      </div>
      <!-- /.row -->
    </div>
    <!-- /.container -->
  </section>
  <!-- /#login-form -->
</template>

<script>
export default {
  name: 'login-menu'
}
</script>

<style scoped>

</style>
