
<template>
  <div id='wrap_all'>
    <div id='main' data-scroll-offset='88'>
      <section id="banner">

        <div id="videoBlock">


          <video id="Player"
                  autoplay
                  loop
                  muted >

            <source
                    src="../assets/img/output_HD1080.mp4"
                    type="video/mp4">
            Your browser does not support the video tag.
          </video>
        </div>
        <div id="videoMessage">
          <h2>V Signage</h2>
          <p>Vidtiser Digital Signage Solutions</p>
        </div>
      </section>

      <section
              id="main"
              class="container"
              style="position: relative;">

        <section class="box special">
          <header class="major">
            <h2>The power of digital signage</h2>
            <p>Vidtiser offers cost-effective digital signage and interactive solutions. Our turn-key digital signage solutions include hardware, software, creative content design, installation and support. We focus on finding the right match between technology and affordability for our customers. Whether you are a small business or large corporation, be it a single stand-alone unit or an enterprise-wide network. Vidtiser can provide you with a custom digital signage solution that is suitable for your specific need and priced to your budget</p>


          </header>
        </section>

        <section class="box special features">
          <div class="features-row">
            <section>
              <agile class="image featured" :nav-buttons="false" :autoplay-speed="7000" :speed="2500" fade="fade" pause-on-hover="pause-on-hover" pause-on-dots-hover="pause-on-dots-hover" autoplay="autoplay"><img class="slide" src="../assets/img/corp-img00.png"/><img class="slide" src="../assets/img/brandwatch-image-1.jpg"/><img class="slide" src="../assets/img/Corporate-Digital-Signage.jpg"/></agile>

              <h3>Corporate Digital Signage</h3>
              <p>Drive brand awareness, engagement and growth with your customers while improving day-to-day service standards, safety and economy</p>
            </section>
            <section>
              <agile class="image featured" :nav-buttons="false" :autoplay-speed="7000" :speed="2500" fade="fade" pause-on-hover="pause-on-hover" pause-on-dots-hover="pause-on-dots-hover" autoplay="autoplay"><img class="slide" src="../assets/img/them1.png"/><img class="slide" src="../assets/img/digital-signage-covid.jpg"/><img class="slide" src="../assets/img/digital-signage-covid2.jpg"/></agile>

              <h3>Entry Control and Thermal Smart Sensors</h3>
              <p>We have a selection of products specifically curated to deal with the entry control and monitoring challenges being faced by businesses due to the COVID-19 Pandemic.</p>
            </section>

          </div>
          <div class="features-row">
            <section>
              <agile class="image featured" :nav-buttons="false" :autoplay-speed="7000" :speed="2500" fade="fade" pause-on-hover="pause-on-hover" pause-on-dots-hover="pause-on-dots-hover" autoplay="autoplay"><img class="slide" src="../assets/img/Indoor_Retail.jpg"/><img class="slide" src="../assets/img/retail-1.jpg"/><img class="slide" src="../assets/img/retail-2.jpg"/></agile>

              <h3>Retail Digital Signage</h3>
              <p>Digital signage is more eye-catching and memorable than static media. They have the versatility to inform, entertain or sell and enhance branding and image</p>
            </section>

            <section>
              <agile class="image featured" :nav-buttons="false" :autoplay-speed="7000" :speed="2500" fade="fade" pause-on-hover="pause-on-hover" pause-on-dots-hover="pause-on-dots-hover" autoplay="autoplay"><img class="slide" src="../assets/img/DigitalSignage-restaurant.jpg"/><img class="slide" src="../assets/img/restaurant-1.jpg"/><img class="slide" src="../assets/img/restaurant-2.jpg"/></agile>

              <h3>Restaurant Digital Signage</h3>
              <p>Digital menu boards are all the rage, and for good reason. The benefits of using a digital menu board make it a no-brainer for all restaurants in 2019. With the ability to instantly update your menu, you will ensure that customers are seeing the latest, greatest list of your offerings.
                Updating traditional signage can be a headache—not to mention expensive. Converting over to a digital menu will eliminate the question, “Is it time to update my menus?”</p>
            </section>
            <p></p>


          </div>
          <div class="features-row">
            <section>
              <agile class="image featured" :nav-buttons="false" :autoplay-speed="7000" :speed="2500" fade="fade" pause-on-hover="pause-on-hover" pause-on-dots-hover="pause-on-dots-hover" autoplay="autoplay"><img class="slide" src="../assets/img/Interactive-Kiosk.png"/><img class="slide" src="../assets/img/Digital-Signage-1.jpg"/></agile>

              <h3>Smart Digital Signage</h3>
              <p>Reliability, scalability and security guarantee, as well as plenty of flexibility which allows the service to be tailored to the needs of the clients and integrated with external systems.</p>
            </section>

            <section>
              <agile class="image featured" :nav-buttons="false" :autoplay-speed="7000" :speed="2500" fade="fade" pause-on-hover="pause-on-hover" pause-on-dots-hover="pause-on-dots-hover" autoplay="autoplay"><img class="slide" src="../assets/img/Transportation_Sign.jpg"/><img class="slide" src="../assets/img/transport-expert-panel-image-2.jpg"/><img class="slide" src="../assets/img/train-station@2x-1024x608.jpg"/></agile>

              <h3>Transport Hub Digital Signage</h3>
              <p>Airports, busports, train stations and tourism centres need to be able to give relevant and reliable information at a moment’s notice. Keep travellers up-to-date with interactive information at the swipe of a finger</p>
            </section>
            <p></p>


          </div>
          <div class="features-row"></div>
          <section class="box special features">
          <div class="features-row">
            <agile :nav-buttons="false" :autoplay-speed="5000" :speed="2500" fade="fade" pause-on-hover="pause-on-hover" pause-on-dots-hover="pause-on-dots-hover" autoplay="autoplay"><img class="slide" src="../assets/img/Digital-Signage_features_02_M01_Video-Wall.jpg"/><img class="slide" src="../assets/img/55inch-18mm.jpg"/><img class="slide" src="../assets/img/55-inch-lcd-video-wall.jpg"/><img class="slide" src="../assets/img/LCD-video-wall-49“-LG-IPS-panel.jpg"><img class="slide" src="../assets/img/LCD_commercial.jpg"/><img class="slide" src="../assets/img/stretched-lcd.jpg"/></agile>
              <h3>Video Wall - Digital Signage</h3>
            <br>
              <p>Video walls can be used for a variety of use cases, including events, within retail, corporate offices, receptions, restaurants or more. Anywhere you might implement a single screen, providing you have room, could also be used as a multi-screen video wall display</p>

          </div>
            <br>
            <div class="features-row"></div>
          </section>

          <section class="box special features">
            <div class="features-row">
              <agile :nav-buttons="false" :autoplay-speed="5000" :speed="2500" fade="fade" pause-on-hover="pause-on-hover" pause-on-dots-hover="pause-on-dots-hover" autoplay="autoplay"><img class="slide" src="../assets/img/outdoor/86-inch-outdoor-advertising-stands-basic-information.jpg"/><img class="slide" src="../assets/img/outdoor/58-inch-outdoor-lcd-digital-signage-basic-information.jpg"/><img class="slide" src="../assets/img/outdoor/floor-standing-outdoor-totem-basic-waterproof.jpg"><img class="slide" src="../assets/img/outdoor/outdoor-digital-signage-waterproof-1.jpg"/></agile>
              <h3>Outdoor Digital Signage</h3>
              <br>
              <p>Deploying an outdoor digital signage solution requires a thoughtful approach when it comes to the content displayed on it. The overall goal when adopting an outdoor digital signage solution is to ensure that it provides the viewing audience with a great experience while also designing the content so that will help boost ROI by reducing energy consumption and extending the life of the display</p>
            </div>
            <br>
            <div class="features-row"></div>
          </section>
          <section class="box special features">
            <div class="features-row">

            <span class="image featured" ><img style="height: 100%"
                                               src="../assets/img/OLED-Signage-In-Glass.jpg"
                                               alt="Perth digital signage" ></span>
              <h3>OLED Poster - Digital Signage</h3>
              <br>
              <p>The Dual-View Flat OLED Signage is lightweight and thin, featuring back-to-back Full HD screens at only 8.94mm thin making it ideal for retail environments. It can be mounted from the floors, ceilings or walls lending itself to a diverse range of applications.</p>
            </div>
          </section>
        </section>



      </section>
  </div>
  </div>
</template>

<script>

import  VueAgile from "./lcdvideowall"
export default {
  name: "VSignage",
    data () {
        return {
            items: [
                {
                    title: 'Versatility',
                    img: require('../assets/img/Digital-Signage-1.jpg'),
                    des: '',
                },
                {
                    title: 'Digital Signage Software',
                    img: require('../assets/img/Digital-Sigange-cms.png'),
                    des: '',
                },
                {
                    title: 'Digital Menu Board',
                    img: require('../assets/img/IMG_3758.jpg'),
                    des: '',
                }

            ]

        }
    },
    components: {agile: VueAgile},
    mounted() {
      var nextsrc = ["http://www.w3schools.com/html/movie.mp4","http://www.w3schools.com/html/mov_bbb.mp4"];
      var elm = 0; var Player = document.getElementById('Player');
      Player.onended = function(){
        if(++elm < nextsrc.length){
          Player.src = nextsrc[elm]; Player.play();
        }
      }


  }
};
</script>


<style scoped>
  @import url("../assets/css/mainservice.css");
  .test{
    display: flex;

    flex-direction: column;
  }
  #banner {
    background-attachment: scroll,							fixed;
    background-color: #666;
    background-position: top left,						center center;
    background-repeat: repeat,							no-repeat;
    background-size: auto,							cover;
    color: #fff;

    text-align: center;

  }
  #videoBlock {width: 100%; height: 100%;  display: flex;  justify-content: center; align-items: center;}
  #videoMessage {width: 100%; height:100%; position: absolute; top: 0; left: 0;position: inherit}
  #videoMessage *{padding:0.4em; margin:0}
  #videoMessage { color:white;z-index:99 ; display: none;}
  video {
    height: 100%;
    width: 100%;
  }

  @media screen and (max-width: 1680px){
    #banner {padding: 0em 0em 0em 0em}
  }

  @media screen and (max-width: 480px){

    #banner{
      padding: 19em 0 13em 0;}
    #videoMessage{ display: none;}
  }
  @media screen and (max-width: 480px){
    .box .image.featured img {
      border-radius: 0 !important;
      height: 220px;
    }
  }
</style>
