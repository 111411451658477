<template>
  <section
          id="service"
          class="space-60  ser-section">
    <div class="container">
      <div class=" spaceer">
        <div class="heading abt-sec text-center">
          <div class="content">
            <h3>Our <span>Services</span></h3>
          </div>

        </div>

        <div class="ser-wrap d-flex flex-wrap">
          <div class="col-md-6">
            <figure class="ser-item mb-64">
              <div class="img-wrap">
                <a href="https://v-signage.com.au"><img
                        class="img-thumbnail"
                        src="../assets/img/Digital_signage.jpg"
                        alt=""></a>
              </div>
              <figcaption class="content">
                <h3 class="sub_title">V Signage</h3>
                <div class="descrption">VIDTISER'S CUSTOM DIGITAL SIGNAGE SOLUTIONS</div>
                <div class="tags">
                  <a href="https://v-signage.com.au">corporate</a>
                  <a href="https://v-signage.com.au">schools</a>
                  <a href="https://v-signage.com.au">airports</a>
                  <a href="https://v-signage.com.au">interactive</a>
                </div>
                <div class="more-link">
                  <a href="https://v-signage.com.au">Explore more <span class="icon"></span></a>
                </div>
              </figcaption>
            </figure>
          </div>

          <div class="col-md-6">
            <figure class="ser-item mb-64">
              <div class="img-wrap">
                <router-link to="/V-Wall"><img
                        class="img-thumbnail"
                        src="../assets/img/planar.jpg"
                        alt=""></router-link>
              </div>
              <figcaption class="content">
                <h3 class="sub_title">V Wall</h3>
                <div class="descrption">VIDTISER'S VIDEO WALL SOLUTIONS</div>
                <div class="tags">
                  <a><router-link to="/V-Wall">outdoor</router-link></a>
                  <a><router-link to="/V-Wall">indoor</router-link></a>
                  <a><router-link to="/V-Wall">finepitch</router-link></a>
                  <a><router-link to="/V-Wall">art</router-link></a>
                </div>
                <div class="more-link">
                  <router-link to="/V-Wall">Explore more <span class="icon"/></router-link>
                </div>
              </figcaption>
            </figure>
          </div>

          <div class="col-md-6">
            <figure class="ser-item">
              <div class="img-wrap">
                <router-link to="/vCore"><img
                        class="img-thumbnail"
                        src="../assets/img/soft.jpg"
                        alt=""></router-link>
              </div>
              <figcaption class="content">
                <h3 class="sub_title">vCore</h3>
                <div class="descrption">VIDTISER'S SOFTWARE SOLUTIONS</div>
                <div class="tags">
                  <a> <router-link to="/vCore">webapp</router-link></a>
                  <a> <router-link to="/vCore">interactive</router-link></a>
                  <a> <router-link to="/vCore">signage</router-link></a>
                  <a> <router-link to="/vCore">custom solutions</router-link></a>
                </div>
                <div class="more-link">
                  <router-link to="/vCore">Explore more <span class="icon"/></router-link>
                </div>
              </figcaption>
            </figure>
          </div>


          <div class="col-md-6">
            <figure class="ser-item">
              <div class="img-wrap">
                <router-link to="/V-Touch"><img
                        class="img-thumbnail"
                        src="../assets/img/vtouch.jpg"
                        alt=""></router-link>
              </div>
              <figcaption class="content">
                <h3 class="sub_title">V Touch</h3>
                <div class="descrption">VIDTISER'S TOUCH SOLUTIONS</div>
                <div class="tags">
                  <a >   <router-link to="/V-Touch">Touch screen</router-link></a>
                  <a >   <router-link to="/V-Touch">Touch Kiosk</router-link></a>
                  <a>   <router-link to="/V-Touch">Directory boards</router-link></a>
                </div>
                <div class="more-link">
                  <router-link to="/V-Touch">Explore more <span class="icon"/></router-link>
                </div>
              </figcaption>
            </figure>
          </div>
          <div class="col-md-6">
          <figure class="ser-item">
            <div class="img-wrap">
              <router-link to="/AV"><img
                      class="img-thumbnail"
                      src="../assets/img/CORP-AUDIO-VISUAL.jpg"
                      alt=""></router-link>
            </div>
            <figcaption class="content">
              <h3 class="sub_title">Audio Visual</h3>
              <div class="descrption">VIDTISER'S AUDIO VISUAL SOLUTIONS</div>
              <div class="tags">
                <a  ><router-link to="/AV">Video Conference</router-link></a>
                <a  ><router-link to="/AV">Meeting Rooms</router-link></a>
                <a  ><router-link to="/AV">Education</router-link></a>
                <a  ><router-link to="/AV">Art Projects</router-link></a>
              </div>
              <div class="more-link">
                <router-link to="/AV">Explore more <span class="icon"/></router-link>
              </div>
            </figcaption>
          </figure>
        </div>
      </div>
      <!-- /.ser-wrap -->

      </div>
      <!-- /.row -->
    </div>
    <!-- /.container -->
  </section>
</template>

<script>
export default {
  name: 'services_1'
}
</script>

<style scoped>
  .spaceer
  {

    margin-right: 0px;
    margin-left: 0px
  }
  .abt-sec h3:after {
    content: '';
    width: 0px;
    height: 3px;
    background: #05a4db;
    display: block;

  }
  @media only screen and (min-width: 1600px) {
    .abt-sec .content {

      margin: 0px auto;
    }
  }
  @media only screen and (min-width: 1600px){}
  .abt-sec img {
    position: relative;
    right: 0px;
    top: 0px;
  }
</style>
