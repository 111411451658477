import { render, staticRenderFns } from "./ourvendors.vue?vue&type=template&id=44437636&scoped=true&"
import script from "./ourvendors.vue?vue&type=script&lang=js&"
export * from "./ourvendors.vue?vue&type=script&lang=js&"
import style0 from "./ourvendors.vue?vue&type=style&index=0&id=44437636&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44437636",
  null
  
)

export default component.exports