<template>

  <div id="contact100" class="container-contact100">
    <div class="wrap-contact100">

      <form @submit.prevent="checkvalid"  class="contact100-form validate-form">
				<span class="contact100-form-title">
					Send Us A Message
				</span>

        <label class="label-input100" for="first-name">Tell us your name *</label>
        <div class="wrap-input100 rs1-wrap-input100 " >
          <input
                  v-model="$v.contact.Firstname.$model"
                  @blur="$v.contact.Firstname.$touch()"
                  :class="{error: shouldAppendErrorClass($v.contact.Firstname), valid: shouldAppendValidClass($v.contact.Firstname)}"
                  id="first-name"
                  class="input100"
                  type="text"
                  name="first-name"
                  placeholder="First name">
          <span class="focus-input100"></span>
            <p v-if="$v.contact.Firstname.$error" class="error-message">The first name field is required</p>

        </div>
        <div class="wrap-input100 rs2-wrap-input100 " >
          <input
                  v-model="$v.contact.Lastname.$model"
                  @blur="$v.contact.Lastname.$touch()"
                  class="input100"
                  type="text"
                  name="last-name"
                  placeholder="Last name">
          <span class="focus-input100"></span>
        </div>

        <label class="label-input100" for="email">Enter your email *</label>
        <div class="wrap-input100 ">
          <input
                  v-model="$v.contact.email.$model"
                  :class="{error: shouldAppendErrorClass($v.contact.email), valid: shouldAppendValidClass($v.contact.email)}"
                  @blur="$v.contact.email.$touch()"
                 id="email"
                  class="input100"
                  type="email"
                  name="email"
                  placeholder="Eg. example@email.com">
          <span class="focus-input100"></span>
            <div v-if="$v.contact.email.$error">
                <p v-if="!$v.contact.email.email && $v.contact.email.$error" class="error-message">Invalid email address</p>
                <p v-else-if="!$v.contact.Phone.required" class="error-message">The email field is required</p>
            </div>

        </div>

        <label class="label-input100" for="phone">Enter phone number</label>
          <div class="wrap-input100" >
          <input
                  v-model="$v.contact.Phone.$model"
                  :class="{error: shouldAppendErrorClass($v.contact.Phone), valid: shouldAppendValidClass($v.contact.Phone)}"
                  @blur="$v.contact.Phone.$touch()"
                  id="phone"
                  class="input100"
                  type="text"
                  name="phone"
                  placeholder="">
          <span class="focus-input100"></span>
              <div v-if="$v.contact.Phone.$error">
              <p v-if="!$v.contact.Phone.required" class="error-message">The phone number field is required</p>
              <p v-else-if="!$v.contact.Phone.integer" class="error-message">The phone number field should be numbers</p>
              </div>
          </div>
        <label class="label-input100" for="message">Message *</label>
        <div class="wrap-input100 " >
          <textarea
                  v-model="$v.contact.message.$model"
                  :class="{error: shouldAppendErrorClass($v.contact.message), valid: shouldAppendValidClass($v.contact.message)}"
                  @blur="$v.contact.message.$touch()"
                  id="message"
                  class="input100"
                  name="message"
                  placeholder="Write us a message"></textarea>
          <span class="focus-input100"></span>
            <p v-if="$v.contact.message.$error" class="error-message">The message field is required</p>
        </div>

        <div class="container-contact100-form-btn">
          <button  v-show="!sending" class="contact100-form-btn">Send Message</button>
            <button v-show="sending" class="contact100-form-btn">Sending Message...
                <span  class="spinner-grow spinner-grow-sm" style="margin-left: 10px" role="status" aria-hidden="true"></span></button>
        </div>
      </form>

      <div class="contact100-more  flex-col-c-m " :style="{ backgroundImage: 'url(' + bimage + ')' }" >
        <div class="flex-w size1 p-b-47">
          <div class="txt1 p-r-25">
            <span class="lnr lnr-map-marker"></span>
          </div>

          <div class="flex-col size2">
						<span class="txt1 p-b-20">
							Address
						</span>

            <span class="txt2">
							5 Creative St
              Wangara WA 6065
						</span>
          </div>
        </div>

        <div class="dis-flex size1 p-b-47">
          <div class="txt1 p-r-25">
            <span class="lnr lnr-phone-handset"></span>
          </div>

          <div class="flex-col size2">
						<span class="txt1 p-b-20">
							Lets Talk
						</span>

            <span class="txt3">
							<a href="tel:1300945432">1300 945 432</a>
						</span>
          </div>
        </div>
        <div class="dis-flex size1 p-b-47">
          <div class="txt1 p-r-25">
            <span class="lnr lnr-envelope"></span>
          </div>

          <div class="flex-col size2">
						<span class="txt1 p-b-20">
							General Sales
						</span>

            <span class="txt6">
							<a href="mailto:josh.hodgetts@vidtiser.com.au">sales@vidtiser.com.au</a>
						</span>
          </div>
        </div>
      </div>
    </div>
      <toast-container></toast-container>
  </div>


</template>

<script>
    import { required, integer,email} from 'vuelidate/lib/validators'
    import { event } from 'vue-gtag'
export default {
  name: 'contact_form',
  data: function () {
    return {
      sending:false,
      bimage: require('../assets/img/bg-01.jpg'),
      contact: {
        Firstname: '',
        Lastname: '',
        Phone: '',
        email: '',
        message: ''}
    }
  },
    validations: {
        contact: {
            Firstname: {
                required
            },
            Lastname: {

            },
            Phone: {
                integer,
                required
            },
            email: {
                email,
                required
            },
            message: {required}
        }
    },
  mounted () {

  },
  methods: {
    clearForm () {
      for (let field in this.contact) {
        this.contact[field] = ''

      }
    },
      shouldAppendValidClass (field) { // ex: field = $v.form.email
          return !field.$invalid && field.$model && field.$dirty
      },

      shouldAppendErrorClass (field) { // ex: field = $v.form.email
          return field.$error
      },


      checkvalid(){
         this.$v.contact.$touch()
          if (!this.$v.contact.$invalid) {
              this.sending=true;
              this.recaptcha()
          }
     },
      recaptcha() {
          this.$recaptcha('ContactForm').then(() => {

              this.sendemail()
          })
      },
    sendemail() {
      event('Contact Form', { method: 'Google' })
        // Build for data
        let form =  JSON.stringify({form:this.contact})


        // Send form to server
        fetch('/contact', {method: 'post',headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }, body: form}).then( (response)  =>{
            this.sending = false
            if(response.status === 200)
            {
                this.setmodal = false
                return response.json();
            }
            else {

                let myToast = this.$toasted.error("Email not sent");
                myToast.text("Sorry please try again").goAway(4000);
            }
        }).then((myJson) => {
          if (myJson.message =='contact-success' ) {
              this.sending=false;
              let myToast = this.$toasted.success("Email Sent");
              myToast.text("Thank you for your email").goAway(4000);
            this.clearForm()
          } else {
              let myToast = this.$toasted.error("Email not sent");
              myToast.text("Sorry you email was not sent please try again").goAway(4000);

          }
        })

    }
  }
}
</script>

<style scoped>
@import "../../node_modules/vue-on-toast/dist/vue-on-toast.css";
input.error, input.error:focus {
    border-color: #f79483;
}
input.valid, input.valid:focus {
    border-color: #41B883;
}
input:focus {
    border-color: #a8a8a8;
    outline: none;
}
textarea.error, input.error:focus {
    border-color: #f79483;
}
textarea.valid, input.valid:focus {
    border-color: #41B883;
}
textarea:focus {
    border-color: #a8a8a8;
    outline: none;
}
</style>
