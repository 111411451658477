<template>
  <div>
    <transition name="fade">
  <section id="banner" class="home_banner banner" v-show="newBackgroundImage" v-bind:style="{'background-image': 'url(' + require('../assets/img/overlay.png') + '),url(' +  newBackgroundImage  + ')'}">

    <div class="container">
      <div class="row">
        <div class="banner_content">
          <h1 class="main-title white-clr">LED VIDEO WALL AND DIGITAL SIGNAGE SOLUTIONS </h1>
          <p class="white-clr">Digital Signage - LED Video Walls - Audio Visual</p>
          <div class="line"></div>
          <a href="#service" class="scroll-next" ><img style="margin-top: 50px;" src="../assets/img/mouse.png" alt="Digital Signage Perth"></a>
        </div>
      </div>
      <!-- /.row -->
    </div>
    <!-- /.container -->
  </section>
    </transition>
  <!-- /#banner.home_banner -->

<services></services>
  <!-- /#service -->

<work></work>


<about></about>
  <clients></clients>

</div>
</template>

<script>
import { event } from 'vue-gtag'
import Services from './services'
import About from './about'
import Work from './work'
import Clients from "./ourclients";


export default {
  name: 'Index_1',
  props: {
    register: Boolean,
      ordernow: Boolean
  },

  data: function () {
    return {
        active2:false,
        img1:require('../assets/img/arrow-left.png'),
        img2:require('../assets/img/arrow-right.png'),
        bimage2: [require('../assets/img/artisticled.jpg'),require( '../assets/img/55inch-18mm-1.jpg'), require('../assets/img/55-inch-lcd-video-wall-TKB1.jpg'), require('../assets/img/65inch-mm.jpg'), require('../assets/img/led1.jpg'),require( '../assets/img/abt-img1.png')],
      currentNumber: 0,
      timer: null,
      newBackgroundImage: require('../assets/img/touch.jpg')
    }
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0,0);
    },
    startRotation: function () {
      this.timer = setInterval(this.next, 10000)
    },
    next:  function() {

      this.currentNumber =  Math.floor(Math.random() * Math.floor(this.bimage2.length))
      this.newBackgroundImage = this.bimage2[this.currentNumber]
      if (this.currentNumber === 5) { this.currentNumber = 0 }
    },
    recaptcha(data) {
      this.$recaptcha('Expo').then((token) => {
        this.sendpromo(data)

      })
    },
    sendpromo(data){
      fetch('/freeticket', {
        method: 'post', headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }, body: JSON.stringify({Email:data.value})
      }).then( ()  =>{
        this.$swal('Ticket Sent', 'You will receive your free ticket soon', 'success')
      })
    },
    promotion:function (){
      this.$swal({
        imageUrl: require("../assets/img/EllenbrookBusinessExpolow.png"),
        showConfirmButton: true,
        showCancelButton: false,
        imageWidth: 600,
        imageHeight: 600 ,
        width: '500px',
        background: 'white',
        text: 'Enter your Email to receive a free ticket',
        input: 'email',
        inputPlaceholder: 'Enter email',

        showCloseButton: true,
      }).then((result) => {
        if(result.value){
          this.recaptcha(result)
        } else {

        }
      })

    }
  },
  components: {Clients,  Work, About, Services},
  mounted () {
    event('Index', { method: 'Google' })
    this.startRotation()
    setTimeout( ()=>{
      var now = new Date();
      var mydate=new Date('2019-7-5');
      if(now <mydate) {
        if (window.innerWidth > 1000){
          this.promotion()
        }
      }
    },2000)

  }


}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >

  .navbar{
    box-shadow:none
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity 2s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }

  #banner {
    -webkit-animation: fadein 3s; /* Safari and Chrome */
    -moz-animation: fadein 3s; /* Firefox */
    -ms-animation: fadein 3s; /* Internet Explorer */
    -o-animation: fadein 3s; /* Opera */
    animation: fadein 3s;

    background-attachment: scroll,							fixed;
    background-color: #666;
    background-image: url("../assets/img/overlay.png"),url('../assets/img/e1473889500666.jpg');
    background-position: top left,						center center;
    background-repeat: repeat,							no-repeat;
    background-size: auto,							cover;
    color: #fff;

    text-align: center;
  }

  @media screen and (max-width: 480px){
    #banner{
      padding-top: 0px
    }

  }

</style>
