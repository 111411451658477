import Vue from 'vue'
import Router from 'vue-router'
import index from '@/components/index'
import vCore from '@/components/VCore'
import vsignage from '@/components/VSignage'
import vwall from '@/components/VWall'
import vmedia from '@/components/VMedia'
import blog1 from '@/components/blog1'
import vtouch from '@/components/VTouch'
import av from '@/components/AV'
import blog2 from "../components/blog2";

Vue.use(Router)

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'Index',
            component: index,

            meta: {
                title: 'Vidtiser, LED Signs | Digital Signage | Audio Visual Solutions Perth. We Deliver Your Vision',
                breadcrumb: 'LED and Digital Signage Solutions' ,
                metaTags: [
                    {
                        name: 'description',
                        content: 'Vidtiser provides Digital Signage | Audio Visual | LED Video Wall Solutions Perth. Our team includes designers and technologists who share a passion for generating great ideas and translating them into intelligent and innovative solutions.. Vidtiser Delivers Your Vision.'
                    },
                    {
                        property: 'og:description',
                        content: 'Vidtiser provides Digital Signage | Audio Visual | LED Video Wall Solutions Perth . Our team includes designers and technologists who share a passion for generating great ideas and translating them into intelligent and innovative solutions. . Vidtiser Delivers Your Vision.'
                    },
                    {property: 'og:title', content: 'Vidtiser | We Deliver Your Vision, Perth digital signage ← Vidtiser'},{property: 'og:type', content: 'website'}
                ]
            }
        },
        {
            path: '/vCore',
            name: 'vCore',
            component: vCore,
            meta: {
                title: 'vCore - Software solution.',
                breadcrumb: 'vCore - Software solution.' ,
                metaTags: [
                    {
                        name: 'description',
                        content: 'Vidtisers Enterprise software solutions.'
                    },
                    {
                        property: 'og:description',
                        content: 'Vidtisers Enterprise software solutions.'
                    },
                    {property: 'og:title', content: 'vCore - Vidtisers software solution ← Vidtiser'},{property: 'og:type', content: 'website'}
                ]
            }

        },
        {
            path: '/V-Media',
            name: 'V-Media',
            component: vmedia,
            meta: {
                title: 'V-Media - Digital Signage Content Creation.',
                breadcrumb:  'V-Media - Digital Signage Content Creation.',
                metaTags: [
                    {
                        name: 'description',
                        content: 'Vidtisers digital signage content creation.'
                    },
                    {
                        property: 'og:description',
                        content: 'Vidtisers digital signage content creation.'
                    },
                    {property: 'og:title', content: 'V-Media - Vidtisers content creation ← Vidtiser'},{property: 'og:type', content: 'website'}
                ]
            }
        },
        {
            path: '/V-Signage',
            name: 'V-Signage',
            component: vsignage,
            meta: {
                title: 'V-Signage - Digital Signage Solution. ',
                breadcrumb:  'V-Signage - Digital Signage Solution.' ,
                metaTags: [
                    {
                        name: 'description',
                        content: 'Vidtisers digital signage solutions.'
                    },
                    {
                        property: 'og:description',
                        content: 'Vidtisers digital signage solutions.'
                    },
                    {property: 'og:title', content: 'V-Signage - Vidtisers digital signage solution ← Vidtiser'},{property: 'og:type', content: 'website'}
                ]
            }
        },
        {
            path: '/V-Touch',
            name: 'V-Touch',
            component: vtouch,
            meta: {
                title: 'V-Touch - Touch Screen Solution. ',
                breadcrumb: 'V-Touch - Touch Screen Solution.' ,
                metaTags: [
                    {
                        name: 'description',
                        content: 'Vidtisers touch screen solutions.'
                    },
                    {
                        property: 'og:description',
                        content: 'Vidtisers touch screen solutions.'
                    },
                    {property: 'og:title', content: 'V-Touch - Vidtisers touch screen solution ← Vidtiser'},{property: 'og:type', content: 'website'}
                ]
            }
        },
        {
            path: '/V-Wall',
            name: 'V-Wall',
            component: vwall,
            meta: {
                title: 'V-Wall - LED Video Wall Displays. ',
                breadcrumb:  'V-Wall - LED Video Wall Displays.',
                metaTags: [
                    {
                        name: 'description',
                        content: 'LED walls | LED video walls Displays'
                    },
                    {
                        property: 'og:description',
                        content: 'LED walls |  LED Video Wall Displays'
                    },
                    {property: 'og:title', content: 'V-Wall - LED Video walls perth ← Vidtiser'},{property: 'og:type', content: 'website'}
                ]
            }
        },
        {
            path: '/AV',
            name: 'Audio Visual Solution',
            component: av,
            meta: {
                title: 'AV Solution',
                breadcrumb: 'Audio Visual Solution.',
                metaTags: [
                    {
                        name: 'description',
                        content: 'Audio Visual Solution'
                    },
                    {
                        property: 'og:description',
                        content: 'Audio Visual Solutions'
                    },
                    {property: 'og:title', content: 'Audio Visual Solution ← Vidtiser'},{property: 'og:type', content: 'website'}
                ]
            }
        },
        {
            path: '/blog1',
            name: 'blog1',
            component: blog1,
            meta: {
                title: 'Yagan Square.',
                breadcrumb: 'Yagan Square LED Video Wall' ,
                metaTags: [
                    {
                        name: 'description',
                        content: 'Sound and Broadcast installation.'
                    },
                    {
                        property: 'og:description',
                        content: 'Sound and Broadcast installation.'
                    },
                    {property: 'og:title', content: 'Yagan Square ← Vidtiser'},{property: 'og:type', content: 'website'}
                ]
            }
        },
        {
            path: '/support',
            beforeEnter() {location.href = 'https://support.vidtiser.com.au'}
        },
        {
            path: '/blog2',
            name: 'blog2',
            component: blog2,
            meta: {
                title: 'Ellenbrook Art Awards.',
                breadcrumb: 'Indoor LED Video Wall',
                metaTags: [
                    {
                        name: 'description',
                        content: 'Ellenbrook Art Awards.'
                    },
                    {
                        property: 'og:description',
                        content: 'LED Video Wall'
                    },
                    {property: 'og:title', content: 'Ellenbrook Art Awards ← Vidtiser'},{property: 'og:type', content: 'website'}
                ]
            }
        },

        { path: '*', redirect: '/' },
    ],
    scrollBehavior() {
        setTimeout(()=>{
            document.getElementById('app').scrollIntoView();
        },1500)

    }
})

