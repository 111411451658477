<template>
  <section id="work" class="space-200 abt-sec backtest">
    <div class="container">

        <div class="heading text-center">
          <div class="content">
          <h3>Our <span>Work</span></h3>
          </div>

          <div class="bottom-text"></div>
          <carousel
                  :paginationPadding="10"
                  :perPageCustom="[[367, 1],[768, 2] ,[1024, 2], [1400, 3]]"
                  :mouse-drag="true"
                  :spacePadding='50'
                  >
            <slide
                    v-for="item in items"
                    :key="item.id"
                    class="sl_item " >
              <img :src="item.img"
                      class="img-thumbnail"
                      alt="">
              <h4 class="sub_title">{{ item.title }}</h4>
              <div class="tags">
                <router-link
                        v-for="tag in item.tags"
                        :key="tag"
                        :to="item.page" >{{ tag }}</router-link>
              </div>
              <p class="pl-3 pr-3">{{ item.des }}</p>
              <div
                      v-if="item.page !=''"
                      class="more-link">

                <router-link :to="item.page">Learn More</router-link>

              </div>
              <div
                      v-else
                      v-html="title" />

            </slide>

          </carousel>

        </div>
    </div>

  </section>
</template>

<script>
export default {
  name: 'work_1',
  data () {
    return {
      items: [
      {
          title: 'DOOH Oudoor LED',
          img: require('../assets/img/goplex-1.jpg'),
          tags: ['LED', 'Sound', 'DOOH'],
          des: 'Vidtisers stunning bright and robust out door range GO-PLEX, was used to install a 6 x 2.8mtr super bright screen, with a 24/7 robust CMS and a 27/7 monitoring system.',
          page: ''
        },
        {
          title: 'BG&E Logitech Rooms',
          img: require('../assets/img/bge_rooms.jpg'),
          tags: ['Audio Visual', 'Sound', 'Conference'],
          des: 'With its advanced camera and speaker technology, the Rally Bar is perfect for large conference rooms and boardrooms, providing exceptional audio and video coverage even in the most challenging of spaces.',
          page: ''
        },
        {
          title: 'Funlabs, Forrest Chase - Karrinyup - Fremantle',
          img: require('../assets/img/funlabs.jpg'),
          tags: ['Audio Visual', 'Sound', 'Lighting'],
          des: 'Vidtiser completed 3 large iconic entertainment venues within a short period. These venues had big sound and lighting with many visual aspects',
          page: ''
        },
               {
          title: 'The Rocks Church Giant LED Video wall',
          img: require('../assets/img/the_rocks_church_2.jpg'),
          tags: ['V-Wall', 'V-Signage', 'Spider'],
          des: 'Our very popular Spider series indoor LED video wall, The Rocks church needed something to dramatically change their stage, the spider series helpt change how they integrate display with lighting. The super bright and colourful LED panels bosted their stage. A 9mtr x 5 mtr 2.9mm pixel pitch LED video wall',
          page: ''
        },
        {
          title: 'Centerpoint Church LED',
          img: require('../assets/img/CENTREPOINT_LED_VIDEO_WALL.jpg'),
          tags: ['V-Wall', 'V-Signage','Spider'],
          des: 'Our new Spider series indoor LED video wall, built for churches and schools.A 5mtr x 3 mtr 2.6mm pixel pitch LED video wall. Centerpoint Church needed something to help bring life to there stage and online streaming.',
          page: ''
        },
        {
          title: 'South32 Video Wall',
          img: require('../assets/img/south32LED.jpg'),
          tags: ['V-Wall', 'V-Signage', 'vCore'],
          des: 'Another excellent installation from the team at Vidtiser. South 32 Worsley - GOPLEX Q3.1mm pixel pitch. An outdoor video wall that can hold up to the extreme mining environments',
          page: ''
        },
        {
          title: 'Ellenbrook Arts',
          img: require('../assets/img/ellenbrookartfront.jpg'),
          tags: ['V-Wall', 'V-Signage', 'vCore'],
          des: 'Vidtiser teamed up with Ellenbrook arts/Ellenbrook Central/City of Swan/LWP and Prime projects to help the engage the public through V-wall and V-touch solutions',
          page: '/blog2'
        },
        {
          title: 'Yagan Square',
          img: require('../assets/img/180322-Yagan-Featured-Post-Thumbnail.jpg'),
          tags: ['Signage', 'LED', 'Qflex'],
          des: 'Vidtiser was contracted to do the tower sound and the live production system. The new Qflex speakers light up the ultramodern visual feature with world-class sound.',
          page: '/blog1'
        },
        {
          title: 'North Bridge Piazza',
          img: require('../assets/img/Piazza_Dan_Printsize-32.jpg'),
          tags: ['LED', 'Sound', 'Software'],
          des: 'Vidtiser was contracted to redesign the playback system and consult on the LED video wall at the entertainment hotspot, improving the capabilities and reliability of an already outstanding venue',
          page: ''
        },
        {
          title: 'Digital Menu Board',
          img: require('../assets/img/pizza_italia.jpg'),
          tags: ['Digital signage', 'Media'],
          des: 'Runchy needed a great content management system and content for their menu boards. Vidtiser supplied and installed a complete solution that was ready to go as soon as they were',
          page: ''
        }

      ]

    }
  },
  mounted: function () {

  }
}
</script>

<style scoped>
  .abt-sec h3:after {
    content: '';
    width: 0px;
    height: 3px;
    background: #05a4db;
    display: block;

  }
  @media only screen and (min-width: 1600px) {
    .abt-sec .content {
      width: 545px;
      margin: 0px auto;
    }
  }
  .line.white:after {
    border-color: #c1c1c1;
  }
  .carousel {
    /* any dimensions are fine, it can be responsive with max-width */
    width: 300px;
    height: 350px;
  }
.backtest{
  background-attachment: scroll, fixed;
  padding-bottom: 30px;
  padding-top: 30px;
  background-color: rgba(163, 171, 176, 0.17);
background-size: cover;


}
.sl_item{
  margin: 25px 5px;
}
.Vue-Carousel-slide
  {
    max-width: 95%;
  }
  .item {
    background-size: cover;
    background-position: 50% 50%;
    width: 100%;
    height: 100%;
  }

  .item img {
    visibility: hidden;
  }


</style>
