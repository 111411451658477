<template>
<div>
  <section
          id="about"

          class="space-200 abt-sec ">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-6">
          <div class="content">
            <h3>About <span>Vidtiser</span></h3>
            <p>Vidtiser provides stunning smart LED video wall and Digital Signage solutions. Our team includes designers and technologists with over 15 years of experience and who share a passion for generating great ideas and translating them into intelligent and innovative solutions for our clients.
              We provide high impact LED displays that last and solution to evolve with changing technology, we strive to offer creative solutions, reliable products, and dependable service for our industry-leading LED display technologies. Driven by excellence to meet your standards, Vidtiser offers one-of-a-kind designs for your tour, event or installation through our award-winning line of LED video walls and video screens.</p>
          </div>
        </div>
        <!-- /.col-md-6 -->
        <div class="col-lg-6">
          <div class="container">
            <div v-if="!isMobile()" class="cr-container">

              <input   id="select-img-1" name="radio-set-1" type="radio" class="cr-selector-img-1"  checked/>
              <label for="select-img-1" class="cr-label-img-1">1</label>

              <input id="select-img-2" name="radio-set-1" type="radio" class="cr-selector-img-2" />
              <label for="select-img-2" class="cr-label-img-2">2</label>

              <input id="select-img-3" name="radio-set-1" type="radio" class="cr-selector-img-3" />
              <label for="select-img-3" class="cr-label-img-3">3</label>

              <input id="select-img-4" name="radio-set-1" type="radio" class="cr-selector-img-4"   />
              <label for="select-img-4" class="cr-label-img-4">4</label>

              <div class="clr"></div>
              <div class="cr-bgimg">
                <div>
                  <span>Slice 1 - Image 1</span>
                  <span>Slice 1 - Image 2</span>
                  <span>Slice 1 - Image 3</span>
                  <span>Slice 1 - Image 4</span>
                </div>
                <div>
                  <span>Slice 2 - Image 1</span>
                  <span>Slice 2 - Image 2</span>
                  <span>Slice 2 - Image 3</span>
                  <span>Slice 2 - Image 4</span>
                </div>
                <div>
                  <span>Slice 3 - Image 1</span>
                  <span>Slice 3 - Image 2</span>
                  <span>Slice 3 - Image 3</span>
                  <span>Slice 3 - Image 4</span>
                </div>
                <div>
                  <span>Slice 4 - Image 1</span>
                  <span>Slice 4 - Image 2</span>
                  <span>Slice 4 - Image 3</span>
                  <span>Slice 4 - Image 4</span>
                </div>
              </div>
              <div class="cr-titles">
                <h33><span ><br>Local</span><span style="margin-top: 250px">We strive to support our customers every step of tha way.</span></h33>
                <h33><span >Creative Leadership</span><span style="margin-top: 250px">Your creativity should not be limited by the products.</span></h33>
                <h33><span><br>Application</span><span style="margin-top: 250px">We strive to offer products and services for virtually any and all application.</span></h33>
                <h33><span>Support and Service</span><span style="margin-top: 250px">We offer industry leading warranty programs, support and service.</span></h33>
              </div>
            </div>
          </div>
        </div>

      </div>
      </div>
      <!-- /.row -->

    <!-- /.container -->

  </section>
  <!-- /#about -->
</div>
</template>

<script>
    export default {
        name: "about_1",
      data() {
        return {
          additional: [true],
          checked: false,
          checked1: true,
          checked2: false,
          checked3: false
        }
      },
      methods: {

          check_one: ()=>{
            this.additional = [];
          },
          onToggleState(key) {
          this.state[key] = true
        },
        isMobile() {
          if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            return true
          } else {
            return false
          }
        }
      }
    }
</script>

<style scoped>
  @font-face {
    font-family: 'BebasNeueRegular';
    src: url('../assets/fonts/BebasNeue-webfont.eot');
    src: url('../assets/fonts/BebasNeue-webfont.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/BebasNeue-webfont.woff') format('woff'),
    url('../assets/fonts/BebasNeue-webfont.ttf') format('truetype'),
    url('../assets/fonts/BebasNeue-webfont.svg#BebasNeueRegular') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  input[type="checkbox"] + label, input[type="radio"] + label {
    text-decoration: none;
    color: #777;
    display: inline-block;
    font-size: 1.2em;
    font-weight: 300;
    padding-left: .78em;
    padding-right: 0.75em;
    position: relative;
  }
  .container{
    width: 100%;
    height: 100%;
    position: relative;
    text-align: center;
    padding-top: 110px;
  }
  .clr{
    clear: both;
  }
  .container > header{
    padding: 20px 30px 10px 30px;
    margin: 0px 20px 10px 20px;
    position: relative;
    display: block;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.2);
    text-align: center;
  }
  .container > header h1{
    font-family: 'BebasNeueRegular', 'Arial Narrow', Arial, sans-serif;
    font-size: 35px;
    line-height: 35px;
    position: relative;
    font-weight: 400;
    color: rgba(163, 171, 176, 0.9);
    text-shadow: 1px 1px 1px rgba(0,0,0,0.1);
    padding: 0px 0px 5px 0px;
  }
  .container> header h1 span{
    color: #a3abb0;
    text-shadow: 0px 1px 1px rgba(255,255,255,0.8);
  }
  .container> header h2{
    font-size: 16px;
    font-style: italic;
    color: #2d6277;
    text-shadow: 0px 1px 1px rgba(255,255,255,0.8);
  }
  /* Header Style */
  .codrops-top{
    line-height: 24px;
    font-size: 11px;
    background: rgba(255, 255, 255, 0.4);
    text-transform: uppercase;
    z-index: 9999;
    position: relative;
    box-shadow: 1px 0px 2px rgba(0,0,0,0.2);
  }
  .codrops-top a{
    padding: 0px 10px;
    letter-spacing: 1px;
    color: #333;
    text-shadow: 0px 1px 1px #fff;
    display: block;
    float: left;
  }
  .codrops-top a:hover{
    background: #fff;
  }
  .codrops-top span.right{
    float: right;
  }
  .codrops-top span.right a{
    float: left;
    display: block;
  }

  p.codrops-demos{
    text-align:center;
    display: block;
    padding: 14px;
  }
  p.codrops-demos a,
  p.codrops-demos a.current-demo,
  p.codrops-demos a.current-demo:hover{
    display: inline-block;
    font-size: 11px;
    font-style: italic;
    width: 60px;
    height: 60px;
    margin: 0px 3px;
    line-height: 60px;
    border-radius: 50%;
    font-weight: 800;
    box-shadow: 1px 1px 1px rgba(0,0,0,0.05) inset;
    color: #fff;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.1);
    background: rgba(104,171,194,0.5);
  }
  p.codrops-demos a:hover{
    background: #4fa2c4;
  }
  p.codrops-demos a.current-demo,
  p.codrops-demos a.current-demo:hover{
    color: rgba(104,171,194,1);
    background: rgba(255,255,255,0.9);
    box-shadow: 0px 1px 1px rgba(0,0,0,0.1);
  }
  .cr-container{
    width: 640px;
    height: 440px;
    position: relative;
    margin: 0 auto;
    overflow: hidden;
    border: 20px solid #fff;
    box-shadow: 1px 1px 3px rgba(0,0,0,0.1);
  }
  .cr-container label{
    font-style: italic;
    width: 150px;
    height: 30px;
    cursor: pointer;
    color: #fff;
    line-height: 32px;
    font-size: 24px;
    float:left;
    position: relative;
    margin-top:350px;
    z-index: 100;
  }
  .cr-container label:before{
    content:'';
    width: 34px;
    height: 34px;
    background: rgba(163, 171, 176, 0.9);
    position: absolute;
    left: 51%;
    margin-left: -17px;
    border-radius: 50%;
    box-shadow: 0px 0px 0px 4px rgba(255,255,255,0.3);
    z-index:-1;
  }
  .cr-container label:after{
    width: 1px;
    height: 400px;
    content: '';
    background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,255,255,0)), color-stop(100%,rgba(255,255,255,1)));
    background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
    background: -o-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
    background: -ms-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
    background: linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );
    position: absolute;
    bottom: -20px;
    right: 0px;
  }
  .cr-container label.cr-label-img-4:after{
    width: 0px;
  }
  .cr-container input.cr-selector-img-1:checked ~ label.cr-label-img-1,
  .cr-container input.cr-selector-img-2:checked ~ label.cr-label-img-2,
  .cr-container input.cr-selector-img-3:checked ~ label.cr-label-img-3,
  .cr-container input.cr-selector-img-4:checked ~ label.cr-label-img-4{
    color: #a3abb0;
  }
  .cr-container input.cr-selector-img-1:checked ~ label.cr-label-img-1:before,
  .cr-container input.cr-selector-img-2:checked ~ label.cr-label-img-2:before,
  .cr-container input.cr-selector-img-3:checked ~ label.cr-label-img-3:before,
  .cr-container input.cr-selector-img-4:checked ~ label.cr-label-img-4:before{
    background: #fff;
    box-shadow: 0px 0px 0px 4px rgba(104,171,194,0.6);
  }
  .cr-container input{
    display: none;
  }
  .cr-bgimg{
    width: 600px;
    height: 400px;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 1;
  }
  .cr-bgimg{
    background-repeat: no-repeat;
    background-position: 0 0;
  }
  .cr-bgimg div{
    width: 150px;
    height: 100%;
    position: relative;
    float: left;
    overflow: hidden;
    background-repeat: no-repeat;
  }
  .cr-bgimg div span{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 150px;
    z-index: 2;
    text-indent: -9000px;
  }
  .cr-bgimg div:nth-child(odd) span{
    left: -150px;
  }
  .cr-container input.cr-selector-img-1:checked ~ .cr-bgimg,
  .cr-bgimg div span:nth-child(1){
    background-image: url(../assets/img/retailsign.jpg);
  }
  .cr-container input.cr-selector-img-2:checked ~ .cr-bgimg,
  .cr-bgimg div span:nth-child(2){
    background-image: url(../assets/img/artisticled.jpg);
  }
  .cr-container input.cr-selector-img-3:checked ~ .cr-bgimg,
  .cr-bgimg div span:nth-child(3){
    background-image: url(../assets/img/touch.jpg);
  }
  .cr-container input.cr-selector-img-4:checked ~ .cr-bgimg,
  .cr-bgimg div span:nth-child(4){
    background-image: url(../assets/img/service.jpg);

  }
  .cr-bgimg div:nth-child(1) span{
    background-position: 0px 0px;
  }
  .cr-bgimg div:nth-child(2) span{
    background-position: -150px 0px;
  }
  .cr-bgimg div:nth-child(3) span{
    background-position: -300px 0px;
  }
  .cr-bgimg div:nth-child(4) span{
    background-position: -450px 0px;
  }

  .cr-container input:checked ~ .cr-bgimg div span{
    -webkit-animation: slideOutRight 0.6s ease-in-out;
    -moz-animation: slideOutRight 0.6s ease-in-out;
    -o-animation: slideOutRight 0.6s ease-in-out;
    -ms-animation: slideOutRight 0.6s ease-in-out;
    animation: slideOutRight 0.6s ease-in-out;
  }
  @-webkit-keyframes slideOutRight{
    0%{ left: 0px; }
    100%{ left: 150px; }
  }
  @-moz-keyframes slideOutRight{
    0%{ left: 0px; }
    100%{ left: 150px; }
  }
  @-o-keyframes slideOutRight{
    0%{ left: 0px; }
    100%{ left: 150px; }
  }
  @-ms-keyframes slideOutRight{
    0%{ left: 0px; }
    100%{ left: 150px; }
  }
  @keyframes slideOutRight{
    0%{ left: 0px; }
    100%{ left: 150px; }
  }
  .cr-container input:checked ~ .cr-bgimg div:nth-child(even) span{
    -webkit-animation: slideOutLeft 0.6s ease-in-out;
    -moz-animation: slideOutLeft 0.6s ease-in-out;
    -o-animation: slideOutLeft 0.6s ease-in-out;
    -ms-animation: slideOutLeft 0.6s ease-in-out;
    animation: slideOutLeft 0.6s ease-in-out;
  }
  @-webkit-keyframes slideOutLeft{
    0%{ left: 0px; }
    100%{ left: -150px; }
  }
  @-moz-keyframes slideOutLeft{
    0%{ left: 0px; }
    100%{ left: -150px; }
  }
  @-o-keyframes slideOutLeft{
    0%{ left: 0px; }
    100%{ left: -150px; }
  }
  @-ms-keyframes slideOutLeft{
    0%{ left: 0px; }
    100%{ left: -150px; }
  }
  @keyframes slideOutLeft{
    0%{ left: 0px; }
    100%{ left: -150px; }
  }
  .cr-container input.cr-selector-img-1:checked ~ .cr-bgimg div span:nth-child(1),
  .cr-container input.cr-selector-img-2:checked ~ .cr-bgimg div span:nth-child(2),
  .cr-container input.cr-selector-img-3:checked ~ .cr-bgimg div span:nth-child(3),
  .cr-container input.cr-selector-img-4:checked ~ .cr-bgimg div span:nth-child(4)
  {
    -webkit-transition: left 0.5s ease-in-out;
    -moz-transition: left 0.5s ease-in-out;
    -o-transition: left 0.5s ease-in-out;
    -ms-transition: left 0.5s ease-in-out;
    transition: left 0.5s ease-in-out;
    -webkit-animation: none;
    -moz-animation: none;
    -o-animation: none;
    -ms-animation: none;
    animation: none;
    left: 0px;

    z-index: 10;
  }
  .cr-titles h33{
    color: #fff;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.1);
  }
  .cr-titles h33 span{
    z-index: 100;
    position: absolute;
    width: 100%;
    left: 0px;
    text-align: center;
    opacity: 0;
    top: 0%;
  }
  .cr-titles h33 span:nth-child(1){
    left: 0px;
    font-family: 'BebasNeueRegular', 'Arial Narrow', Arial, sans-serif;
    font-size: 70px;
    letter-spacing: 7px;
    -webkit-transition: opacity 0.8s ease-in-out, -webkit-transform 0.8s ease-in-out;
    -moz-transition: opacity 0.8s ease-in-out, -moz-transform 0.8s ease-in-out;
    -o-transition: opacity 0.8s ease-in-out, -o-transform 0.8s ease-in-out;
    -ms-transition: opacity 0.8s ease-in-out, -ms-transform 0.8s ease-in-out;
    transition: opacity 0.8s ease-in-out, transform 0.8s ease-in-out;
  }
  .cr-titles h33 span:nth-child(2){
    margin-top: 84px;
    letter-spacing: 0px;
    background: rgba(227, 236, 241, 0.9);
    font-size: 14px;
    padding: 10px 0px;
    font-style: italic;
    opacity: 0;
    font-family: Cambria, Palatino, "Palatino Linotype", "Palatino LT STD", Georgia, serif;
    -webkit-transition: opacity 0.8s ease-in-out;
    -moz-transition: opacity 0.8s ease-in-out;
    -o-transition: opacity 0.8s ease-in-out;
    -ms-transition: opacity 0.8s ease-in-out;
    transition: opacity 0.8s ease-in-out;
  }
  .cr-container input:checked ~ .cr-titles h33 span:nth-child(1){
    opacity: 0;
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -o-transform: scale(0.5);
    -ms-transform: scale(0.5);
    transform: scale(0.5);
  }
  .cr-container input.cr-selector-img-1:checked ~ .cr-titles h33:nth-child(1) span:nth-child(1),
  .cr-container input.cr-selector-img-2:checked ~ .cr-titles h33:nth-child(2) span:nth-child(1),
  .cr-container input.cr-selector-img-3:checked ~ .cr-titles h33:nth-child(3) span:nth-child(1),
  .cr-container input.cr-selector-img-4:checked ~ .cr-titles h33:nth-child(4) span:nth-child(1){
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
  .cr-container input.cr-selector-img-1:checked ~ .cr-titles h33:nth-child(1) span:nth-child(2),
  .cr-container input.cr-selector-img-2:checked ~ .cr-titles h33:nth-child(2) span:nth-child(2),
  .cr-container input.cr-selector-img-3:checked ~ .cr-titles h33:nth-child(3) span:nth-child(2),
  .cr-container input.cr-selector-img-4:checked ~ .cr-titles h33:nth-child(4) span:nth-child(2){
    opacity: 1;
  }
  /* Media Query: Let's show the inputs on mobile sized browsers because they probably don't support the label trick: */
  @media screen and (max-width: 768px) {
    .cr-container input{
      display: inline;
      width: 24%;
      margin-top: 350px;
      z-index: 100;
      position: relative;
    }
    .cr-container label{
      display: none;
    }
  }
</style>
