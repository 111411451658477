<template>
  <aside class="profile-card">
    <header v-bind:style="{  backgroundImage: 'url(' +  myimage + ')' }">
      <!-- here’s the avatar -->
<a>


</a>
      <!-- the username -->
      <h1 v-bind:style="{color: color}">
        {{title}}
      </h1>

      <!-- and role or location -->
      <h2 v-bind:style="{color: color}" >
        {{Subtitle}}
      </h2>

    </header>

    <!-- bit of a bio; who are you? -->
    <div class="profile-bio">

      <p>
        {{info}}
      </p>

    </div>


  </aside>
</template>

<script>

export default {
  name: 'wrapper_1',
  data(){
    return{
       myimage: this.thimg
    }
  },
  props: {
    title: String,
    info: String,
    Subtitle: String,
    thimg: String,
      color:String
  },

}
</script>

<style  scoped>
  .cover {
    position: absolute;
    top: 160px;
    left: 40px;
    z-index: 2;
  }
  body {
    overflow: hidden;
    background: #bcdee7 url("../assets/img/banner.jpg") no-repeat center center fixed;
    background-size: cover;
    position: fixed;
    padding: 0px;
    margin: 0px;
    width: 100%;
    height: 100%;
    font: normal 14px/1.618em "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
  }

  body:before {
    content: "";
    height: 0px;
    padding: 0px;
    border: 130em solid #313440;
    position: absolute;
    left: 50%;
    top: 100%;
    z-index: 2;
    display: block;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-animation: puff 0.5s 1.8s cubic-bezier(0.55, 0.055, 0.675, 0.19) forwards, borderRadius 0.2s 2.3s linear forwards;
    animation: puff 0.5s 1.8s cubic-bezier(0.55, 0.055, 0.675, 0.19) forwards, borderRadius 0.2s 2.3s linear forwards;
  }

  h1,
  h2 {
    font-weight: 500;
    margin: 0px 0px 5px 0px;
    color: white;
  }

  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 16px;
  }

  p {
    margin: 0px;
  }

  .profile-card {
    background: #33aee5;
    width: 56px;
    height: 56px;
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 2;
    overflow: hidden;
    opacity: 0;
    margin-top: 70px;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-border-radius: 50%;
    border-radius: 50%;
    -webkit-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16), 0px 3px 6px rgba(0, 0, 0, 0.23);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16), 0px 3px 6px rgba(0, 0, 0, 0.23);
    -webkit-animation: init 0.5s 0.2s cubic-bezier(0.55, 0.055, 0.675, 0.19) forwards, moveDown 1s 0.8s cubic-bezier(0.6, -0.28, 0.735, 0.045) forwards, moveUp 1s 1.8s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards, materia 0.5s 2.7s cubic-bezier(0.86, 0, 0.07, 1) forwards;
    animation: init 0.5s 0.2s cubic-bezier(0.55, 0.055, 0.675, 0.19) forwards, moveDown 1s 0.8s cubic-bezier(0.6, -0.28, 0.735, 0.045) forwards, moveUp 1s 1.8s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards, materia 0.5s 2.7s cubic-bezier(0.86, 0, 0.07, 1) forwards;
  }

  .profile-card header {
    padding: 40px 20px 30px 20px;
    display: inline-block;
    float: left;
    min-width: 100%;
    height: auto;
    border-right: 2px dashed #EEEEEE;
    background: #FFFFFF;
    color: #000000;
    margin-top: 50px;
    opacity: 0;
    text-align: center;
    background-size: cover;
    border-radius: 2px;
    -webkit-animation: moveIn 1s 3.1s ease forwards;
    animation: moveIn 1s 3.1s ease forwards;
  }

  .profile-card header h1 {
    color: #e3fffd;
  }

  .profile-card header a {
    display: inline-block;
    text-align: center;
    position: relative;
    margin: 90px 30px;
  }

  .profile-card header a:after {
    position: absolute;
    content: "";
    bottom: 3px;
    right: 3px;
    width: 20px;
    height: 20px;
    border: 4px solid #FFFFFF;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  .profile-card header a > img {

    max-width: 100%;
    max-height: 300px;
    height: auto;

  }

  .profile-card header a:hover > img {
    -webkit-box-shadow: 0px 0px 0px 12px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 0px 12px rgba(0, 0, 0, 0.1);
  }

  .profile-card .profile-bio {
    width: 175px;
    height: 180px;
    display: inline-block;
    float: right;
    padding: 50px 20px 30px 20px;
    background: #FFFFFF;
    color: #333333;
    margin-top: 50px;
    text-align: center;
    opacity: 0;
    -webkit-animation: moveIn 1s 3.1s ease forwards;
    animation: moveIn 1s 3.1s ease forwards;
  }

  .profile-social-links {
    width: 218px;
    display: inline-block;
    float: right;
    margin: 0px;
    padding: 15px 20px;
    background: #FFFFFF;
    margin-top: 50px;
    text-align: center;
    opacity: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-animation: moveIn 1s 3.1s ease forwards;
    animation: moveIn 1s 3.1s ease forwards;
  }

  .profile-social-links li {
    list-style: none;
    margin: -5px 0px 0px 0px;
    padding: 0px;
    float: left;
    width: 25%;
    text-align: center;
  }

  .profile-social-links li a {
    display: inline-block;
    color: red;
    width: 24px;
    height: 24px;
    padding: 6px;
    position: relative;
    overflow: hidden!important;
    -webkit-border-radius: 50%;
    border-radius: 50%;
  }

  .profile-social-links li a i {
    position: relative;
    z-index: 1;
  }

  .profile-social-links li a img,
  .profile-social-links li a svg {
    width: 24px;
  }

  @-webkit-keyframes init {
    0% {
      width: 0px;
      height: 0px;
    }
    100% {
      width: 56px;
      height: 56px;
      margin-top: 0px;
      opacity: 1;
    }
  }

  @keyframes init {
    0% {
      width: 0px;
      height: 0px;
    }
    100% {
      width: 56px;
      height: 56px;
      margin-top: 0px;
      opacity: 1;
    }
  }

  @-webkit-keyframes puff {
    0% {
      top: 100%;
      height: 0px;
      padding: 0px;
    }
    100% {
      top: 50%;
      height: 100%;
      padding: 0px 100%;
    }
  }

  @keyframes puff {
    0% {
      top: 100%;
      height: 0px;
      padding: 0px;
    }
    100% {
      top: 50%;
      height: 100%;
      padding: 0px 100%;
    }
  }

  @-webkit-keyframes borderRadius {
    0% {
      -webkit-border-radius: 50%;
    }
    100% {
      -webkit-border-radius: 0px;
    }
  }

  @keyframes borderRadius {
    0% {
      -webkit-border-radius: 50%;
    }
    100% {
      border-radius: 0px;
    }
  }

  @-webkit-keyframes moveDown {
    0% {
      top: 50%;
    }
    50% {
      top: 40%;
    }
    100% {
      top: 100%;
    }
  }

  @keyframes moveDown {
    0% {
      top: 50%;
    }
    50% {
      top: 40%;
    }
    100% {
      top: 100%;
    }
  }

  @-webkit-keyframes moveUp {
    0% {
      background: #FFB300;
      top: 100%;
    }
    50% {
      top: 40%;
    }
    100% {
      top: 50%;
      background: #E0E0E0;
    }
  }

  @keyframes moveUp {
    0% {
      background: #FFB300;
      top: 100%;
    }
    50% {
      top: 40%;
    }
    100% {
      top: 50%;
      background: #E0E0E0;
    }
  }

  @-webkit-keyframes materia {
    0% {
      background: #E0E0E0;
    }
    50% {
      -webkit-border-radius: 4px;
    }
    100% {
      width: 440px;
      height: 280px;
      background: #FFFFFF;
      -webkit-border-radius: 4px;
    }
  }

  @keyframes materia {
    0% {
      background: #E0E0E0;
    }
    50% {
      border-radius: 4px;
    }
    100% {
      width: 440px;
      height: 280px;
      background: #FFFFFF;
      border-radius: 4px;
    }
  }

  @-webkit-keyframes moveIn {
    0% {
      margin-top: 50px;
      opacity: 0;
    }
    100% {
      opacity: 1;
      margin-top: -20px;
    }
  }

  @keyframes moveIn {
    0% {
      margin-top: 50px;
      opacity: 0;
    }
    100% {
      opacity: 1;
      margin-top: -20px;
    }
  }

  @-webkit-keyframes scaleIn {
    0% {
      -webkit-transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
    }
  }

  @keyframes scaleIn {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }

  @-webkit-keyframes ripple {
    0% {
      transform: scale3d(0, 0, 0);
    }
    50%,
    100% {
      -webkit-transform: scale3d(1, 1, 1);
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes ripple {
    0% {
      transform: scale3d(0, 0, 0);
    }
    50%,
    100% {
      transform: scale3d(1, 1, 1);
    }
    100% {
      opacity: 0;
    }
  }

  @media screen and (min-aspect-ratio: 4/3) {
    body {
      background-size: cover;
    }
    body:before {
      width: 0px;
    }

    @keyframes puff {
      0% {
        top: 100%;
        width: 0px;
        padding-bottom: 0px;
      }
      100% {
        top: 50%;
        width: 100%;
        padding-bottom: 100%;
      }
    }
  }

  @media screen and (min-height: 480px) {
    .profile-card header {
      width: auto;
      height: 300px;
      padding: 30px 20px;
      display: block;
      float: none;
      border-right: none;
    }
    .profile-card .profile-bio {
      width: auto;
      height: auto;
      padding: 15px 20px 30px 20px;
      display: block;
      float: none;
    }
    .profile-social-links {
      width: 100%;
      display: block;
      float: none;
    }

    @keyframes materia {
      0% {
        background: #E0E0E0;
      }
      50% {
        border-radius: 4px;
      }
      100% {
        width: 280px;
        height: 440px;
        background: #FFFFFF;
        border-radius: 4px;
      }
    }
  }
</style>
