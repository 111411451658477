<template>
  <div class="landing">
  <section id="banner">
    <h2>V Touch</h2>
    <p>Vidtisers Touch Screen Solutions</p>
  </section>

  <section id="main" class="container">

    <section class="box special">
      <header class="major">
        <h2>Engaging and Effective </h2>

        <p>Multi-touch kiosk technologies focus on enhancing the user experience, supporting a more informed, engaging and effective transaction experience. And perhaps the most important benefits of multi-touch kiosks are that they boost retail sales results and greatly enhance user satisfaction</p>
      </header>
      <span class="image featured"><img src="../assets/img/tt3.jpg" alt="" /></span>
    </section>

    <section class="box special features">
      <div class="features-row">
        <section>
          <span class="image featured"><img src="../assets/img/touch-table1.jpg" alt="" /></span>
          <h3>Touch Screen Tables</h3>

          <p></p>
        </section>
        <section>
          <span class="image featured"><img src="../assets/img/Wayfinding-Touch-screen.jpg" alt="Perth LED Video Wall" /></span>
          <h3>Wayfinding</h3>

          <p>Our wayfinding solutions are built using the latest technology and advanced software to present  directional information in a simple and easy to use way</p>
        </section>
      </div>
      <div class="features-row">
        <section>
          <span class="image featured"><img src="../assets/img/Touch-Video-Wall.jpg" alt="Perth LED Video Wall" /></span>
          <h3>Touch Screen Video Wall</h3>

          <p></p>
        </section>
        <section>
          <span class="image featured"><img src="../assets/img/Microsoft-Surface-Hub-2-4K-Interactive-Whiteboard-02.jpg" alt="Perth LED Video Wall" /></span>
          <h3>Interactive whiteboard</h3>

          <p>Transform your boardroom or classroom into an interactive space using an interactive whiteboard display</p>
        </section>
      </div>
      <section class="box special features">
        <div class="features-row">
          <agile :nav-buttons="false" :autoplay-speed="5000" :speed="2500" fade="fade" pause-on-hover="pause-on-hover" pause-on-dots-hover="pause-on-dots-hover" autoplay="autoplay"><img class="slide" src="../assets/img/img_home_education_whiteboard.jpg"/><img class="slide" src="../assets/img/Microsoft-Surface-Hub-2-4K-Interactive-Whiteboard-02.jpg"/><img class="slide" src="../assets/img/maxhubtra.jpg"><img class="slide" src="../assets/img/Visitor-Information-Touchscreen.jpg"></agile>
        </div>
        <br>
        <div class="features-row"></div>
      </section>
    </section>



  </section>
  </div>
</template>

<script>
  import  VueAgile from "./lcdvideowall"
export default {
  name: 'V_touch',
  mounted () {

  },
  components: {agile: VueAgile}
}
</script>

<style scoped>

@import url("../assets/css/mainservice.css");
#banner {
  background-attachment: scroll,							fixed;
  background-color: #666;
  background-image: url("../assets/img/overlay.png"), url("../assets/img/vtouch.jpg");
  background-position: top left,						center center;
  background-repeat: repeat,							no-repeat;
  background-size: auto,							cover;
  color: #fff;
  padding: 12em 0 20em 0;
  text-align: center;
}
.box .image.featured img{

  }
@media screen and (max-width: 480px){
  .box .image.featured img {
    border-radius: 0 !important;
    height: 220px;
  }
}
</style>
