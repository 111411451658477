<template>
  <div>
  <div  class="main-header" v-bind:class="{ shrink: isA,opacity:op}">
    <div class="nav1">
      <div class="nav1__content">
        <ul class="nav1__list">

          <li @click="mbtoggler" class="nav1__list-item" ><router-link class="menua" to="/">Home</router-link></li>
          <li @click="mbtoggler" class="nav1__list-item"><router-link class="menua" to="/V-Wall">V Wall</router-link></li>
          <li @click="mbtoggler" class="nav1__list-item"><router-link class="menua" to="/vCore">vCore</router-link></li>
          <li @click="mbtoggler" class="nav1__list-item"><router-link class="menua" to="/V-Touch">V Touch</router-link></li>
          <li @click="mbtoggler" class="nav1__list-item"><router-link class="menua" to="/AV">Audio Visual</router-link></li>
          <li @click="mbtoggler" class="nav1__list-item"><router-link class="menua" to="/support">Support</router-link></li>
          <!--<li @click="clicklogin" class="nav1__list-item"><router-link class="loginPop menua" to="/login">Login</router-link></li> !-->
        </ul>
      </div>
    </div>

    <nav class="navbar"  v-bind:class="{active:MenuisActive}">
      <div class="container p-0">
        <router-link
                @click="$scrollToTop"
                v-bind:style="{opacity:op}"
                class="menua navbar-brand"
                to="/"><img
                class="img-fluid"
                src="../assets/img/logo.png"
                alt=""></router-link>

        <button
                class="mb-toggler navbar-toggler-right"
                v-bind:class="{open:MenuisActive}"
                @click="mbtoggler">

          <span/>
          <span/>
          <span/>
          <span/>
        </button>
      </div>
      <!-- /.container -->
    </nav>

  </div>
  </div>
</template>

<script>

export default {
  name: 'mainn_av',
 data:function() {
return {
    MenuisActive : false,
    isA: false,
     op: 1
 };
},
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
      stickeymove (e) {
      if(e >150)
        this.isA = true
      else
        this.isA = false
    },
    sticky () {
            
            if (window.pageYOffset || document.documentElement.scrollTop > 50) {
                this.isA = true



            } else {

                this.isA = false
            }

    },
    clicknav: function () {

    },
    clicklogin: function () {
      let body = document.querySelector('body')
      this.toggleClass(body, 'nav1-active')
    },
    toggleClass: function(element, stringClass) {
      if (element.classList.contains(stringClass)) {element.classList.remove(stringClass) } else { element.classList.add(stringClass) }

    },
    mbtoggler  () {
        this.MenuisActive =  !this.MenuisActive
      let body = document.querySelector('body')
      this.toggleClass(body, 'nav1-active')


    }
  },
  created(){


  },
  mounted () {
    if (screen.width <= 760) {
      this.isA = true
    } else {
      this.isA = false
    }
   
      window.addEventListener('scroll', this.sticky)
      window.addEventListener('touchmove', ( e ) => {
          this.stickeymove(e.changedTouches[ 0 ].screenY)

      })
  }
}
</script>

<style scoped>
  .nav{
    position: fixed;
    z-index: 1;
    display: none;
  }
</style>
