// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueBreadcrumbs from 'vue-breadcrumbs'

import VueGtag from 'vue-gtag';

Vue.use(VueBreadcrumbs)
import VueCarousel from 'vue-carousel'
import Toasted from 'vue-toasted';
import VueVideoPlayer from 'vue-video-player'
import bootstrap from 'bootstrap-vue'
import Vuelidate from 'vuelidate'


  


Vue.use(Vuelidate)
import VueAnalytics from 'vue-analytics'
import VueInstagram from 'vue-instagram'

Vue.use(VueInstagram)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import jQuery from 'jquery'
import VueSweetalert2 from 'vue-sweetalert2';
//import Tawk from 'vue-tawk'

//Vue.use(Tawk, {
//    tawkSrc: 'https://embed.tawk.to/5cd21eb1d07d7e0c63926b90/default'
//})


Vue.use(VueSweetalert2);
Vue.use(VueVideoPlayer, /* {
  options: global default options,
  events: global videojs events
} */)
window.axios = require('vue-axios');
window.jQuery = jQuery
window.$ = jQuery

require('./assets/css/owl.carousel.min.css')
require('./assets/css/owl.theme.default.min.css')
require('./assets/css/custom.css')
require('./assets/css/responsive.css')

require('./assets/css/raleway.css')

require('./assets/vendor/bootstrap/css/bootstrap.css')
require('./assets/fonts/Linearicons-Free-v1.0.0/icon-font.min.css')
require('./assets/fonts/iconic/css/material-design-iconic-font.min.css')
require('./assets/vendor/animate/animate.css')
require('./assets/vendor/css-hamburgers/hamburgers.min.css')
require('./assets/vendor/animsition/css/animsition.min.css')
require('./assets/vendor/select2/select2.min.css')
require('./assets/vendor/daterangepicker/daterangepicker.css')
require('./assets/css/util.css')
require('./assets/css/main.css')

require('./assets/js/owl.carousel.min.js')

require('popper.js/dist/umd/popper')
require('bootstrap/dist/js/bootstrap')
require('./assets/vendor/animsition/js/animsition.min.js')
require('./assets/js/paymentform')


require('./assets/vendor/countdowntime/countdowntime')

Vue.config.productionTip = false
import { VueReCaptcha } from 'vue-recaptcha-v3'
Vue.use(VueReCaptcha, { siteKey: '6LcrnZoUAAAAADnw1FAJi1x1HI-exU2G0g37Dzh5',  loaderOptions: {
        useRecaptchaNet: true,
        autoHideBadge: true
    } });
Vue.use(VueCarousel)

Vue.use(VueAnalytics, {
    id: 'UA-86310818-2',
    router
})
Vue.use(VueGtag, {
    config: { 
      id: "AW-743646583",
    },
  }, router);
import "@storefront-ui/vue/styles.scss";
router.beforeEach(function (to, from, next) {
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

    if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

    setTimeout(function () {
        window.scrollTo(0, 0)
    },100)

    next()
})



Vue.use(bootstrap)


Vue.use(Toasted)



new Vue({
    router,
    render: h => h(App)
}).$mount('#app');
