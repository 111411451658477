<template>
  <div class="wrapper">
    <div class="container">
      <div class="top" :style="{ backgroundImage: 'url(' +  myimage + ')' }" ></div>
      <div class="bottom">
        <div class="center">
          <div class="details">
            <h1>{{title}}</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="inside">
      <div class="icon"><i class="material-icons">info</i></div>
      <div class="contents">
        <table>
          <tr>
           {{info}}
          </tr>

        </table>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'wrapper_1',
  data(){
    return{
       myimage: this.thimg
    }
  },
  props: {
    title: String,
    info: String,
    thimg: String
  },

}
</script>

<style  lang="scss" scoped>

  .wrapper{
    width: 400px;
    height: 500px;
    background: white;
    margin: auto;
    position: relative;
    overflow: hidden;
    border-radius: 10px 10px 10px 10px;
    transform: scale(0.95);
    transition: box-shadow 0.5s, transform 0.5s;
    box-shadow: 2px 5px 5px rgba(0,0,0,0.2);
  &:hover{
     transform: scale(1);
     box-shadow: 5px 20px 30px rgba(0,0,0,0.2);
   }

  .container{
    width:100%;
    height:100%;
  .top{
    height: 80%;
    width:100%;
    -webkit-background-size: 100%;
    -moz-background-size: 100%;
    -o-background-size: 100%;
    background-size: 100%;
  }
  .bottom{
    width: 100%;
    height: 20%;
    transition: transform 0.5s;
  &.clicked{
     transform: translateX(-50%);
   }
  h1{
    margin:0;
    padding:0;
    text-align: center;
    font-size: 30px;
  }
  p{
    margin:0;
    padding:0;
  }
  .left{
    height:100%;
    width: 50%;
    background: #f4f4f4;
    position:relative;
    float:left;
  .details{
    padding: 20px;
    float: left;
    width: calc(70% - 40px);
  }
  .buy{
    float:right;
    width: calc(30% - 2px);
    height:100%;
    background: #f1f1f1;
    transition: background 0.5s;
    border-left:solid thin rgba(0,0,0,0.1);
  i{
    font-size:20px;
    padding:30px;
    color: #254053;
    transition: transform 0.5s;
  }
  &:hover{
     background: #A6CDDE;
   }
  &:hover i{
     transform: translateY(5px);
     color:#00394B;
   }
  }
  }
  .right{
    width: 50%;
    background: #A6CDDE;
    color: white;
    float:right;
    height:200%;
    overflow: hidden;
  .details{
    padding: 20px;
    float: right;
    width: calc(70% - 40px);
  }
  .done{
    width: calc(30% - 2px);
    float:left;
    transition: transform 0.5s;
    border-right:solid thin rgba(255,255,255,0.3);
    height:50%;
  i{
    font-size:30px;
    padding:30px;
    color: white;
  }
  }
  .remove{
    width: calc(30% - 1px);
    clear: both;
    border-right:solid thin rgba(255,255,255,0.3);
    height:50%;
    background: #BC3B59;
    transition: transform 0.5s, background 0.5s;
  &:hover{
     background: #9B2847;
   }
  &:hover i{
     transform: translateY(5px);
   }
  i{
    transition: transform 0.5s;
    font-size:30px;
    padding:30px;
    color: white;
  }
  }
  &:hover{
  .remove, .done{
    transform: translateY(-100%);
  }
  }
  }
  }
  }

  .inside{
    z-index:9;
    background: #92879B;
    width:140px;
    height:140px;
    position: absolute;
    top: -70px;
    right: -70px;
    border-radius: 0px 0px 200px 200px;
    transition: all 0.5s, border-radius 2s, top 1s;
    overflow: hidden;
  .icon{
    position:absolute;
    right:85px;
    top:85px;
    color:white;
    opacity: 1;
  }
  &:hover{
     width:100%;
     right:0;
     top:0;
     border-radius: 0;
     height:80%;
  .icon{
    opacity: 0;
    right:15px;
    top:15px;
  }
  .contents{
    opacity: 1;
    transform: scale(1);
    transform: translateY(0);
  }
  }
  .contents{
    padding: 5%;
    opacity: 0;
    transform: scale(0.5);
    transform: translateY(-200%);
    transition: opacity 0.2s, transform 0.8s;
  table{
    text-align:left;
    width:100%;
  }
  h1, p, table{
    color: white;
  }
  p{
    font-size:13px;
  }
  }
  }
  }
</style>
