<template>
  <div>
    <footer class="page-footer font-small special-color-dark pt-4">

      <!-- Footer Elements -->
      <div class="container">

        <!-- Social buttons -->
        <ul class="list-unstyled list-inline text-center">
          <li class="list-inline-item">
            <a href="https://www.facebook.com/Vidtiser/" class="btn-floating btn-fb mx-1">
              <i class="fab fa-facebook-f"> </i>
            </a>
          </li>
          <li class="list-inline-item">
            <a  href="https://twitter.com/vidtiser" class="btn-floating btn-tw mx-1">
              <i class="fab fa-twitter"> </i>
            </a>
          </li>
          <li class="list-inline-item">
            <a href="https://www.instagram.com/vidtiser/" class="btn-floating btn-li mx-1">
              <i class="fab fa-instagram"> </i>
            </a>
          </li>
        </ul>
        <!-- Social buttons -->

      </div>
      <!-- Footer Elements -->

      <!-- Copyright -->
      <div class="footer-copyright text-center py-3">© 2020 Copyright: Vidtiser Australia Pty Ltd ACN - 646 371 039
        <a href="https://vidtiser.com.au/">  </a>
      </div>
      <!-- Copyright -->

    </footer>



  </div>
</template>

<script>

export default {
  name: 'mfooter_1',

}
</script>

<style scoped>


</style>
