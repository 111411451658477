<template>
  <div class="landing">
  <section id="banner">
    <h2>Audio Visual</h2>
    <p style="font-size: 30px">Collaboration and unified communication products and services</p>
  </section>

    <section id="service" class="space-60 service-section">
      <div class="container">
        <div class="heading abt-sec text-center">
          <div class="content">
            <h3>Our Professional <span>Services</span></h3>
          </div>
        </div>
        <div class="additional-row">
          <div class="row">
            <div class="col-md-4">
              <div class="service-single text-center">
                <img src="../assets/img/design-icon.png">
                <h4>Design</h4>
                <p>We ensure that your system uses reliable equipment, is designed specifically for your space, and uses technology that works together seamlessly.</p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="service-single text-center">
                <img src="../assets/img/installation-icon.png">
                <h4>Installation</h4>
                <p>Over 18 years of audio visual installation and integration, we ensure organisation, communication and co-ordination is delivered with every AV system we install.</p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="service-single text-center">
                <img src="../assets/img/programming-icon.png">
                <h4>Programming</h4>
                <p>Our in-house programmers are some of the most experienced in the industry. This enables us to simplify the control of complex, state-of-the-art systems and provide user-friendly system interfaces </p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="service-single text-center">
                <img src="../assets/img/project-management-icon.png">
                <h4>Project Management</h4>
                <p>Our industry standard project managers help bring life to you projects and make it work your way.</p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="service-single text-center">
                <img src="../assets/img/support.png">
                <h4>Support</h4>
                <p>Our range of service products are designed to keep your audio visual estate performing at its best. With break/fix support, warranty services and a Service Desk, we are always there to support you</p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="service-single text-center">
                <img src="../assets/img/maintainence.png">
                <h4>Managed AV Services</h4>
                <p>Our managed services are designed to the ever changing audio visual market, thus making it adapt in your environment with systems that are maintained and working 24/7.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="solutions" class="space-60 solutions-section">
      <div class="container">
        <div class="heading abt-sec text-center">
          <div class="content">
            <h3>Our <span>Spaces.</span></h3>
            <p>From a simple meeting room, to a full integrated solution, we can help you succeed with technology that connect people together</p>
          </div>
        </div>
        <div class="additional-row">
          <div class="row">
            <div class="col-md-3 p-0">
              <div class="solution-tabs">
                <ul class="nav nav-pills" v-if="windowwidth >400">
                  <li><a class="active" data-toggle="pill" href="#Business">Business</a></li>
                  <li><a data-toggle="pill" href="#Education">Universities</a></li>
                  <li><a data-toggle="pill" href="#Government">Government</a></li>
                  <li><a data-toggle="pill" href="#Healthcare">Healthcare</a></li>
                  <li><a data-toggle="pill" href="#VenueEntertainment">Venue & Entertainment</a></li>
                  <li><a data-toggle="pill" href="#Broadcast">Broadcast</a></li>
                </ul>
              </div>
            </div>
            <div class="col-md-9 p-0">
              <div class="tab-content">
                <div id="Business" class="tab-pane fade active show">
                  <div class="row">
                    <div class="col-md-6 p-0">
                      <div class="solution-single">
                        <div class="solution-single-inner">
                          <h4>Collaboration</h4>
                          <hr>
                          <p>From a simple meeting room, to a fully connected & automated facility, we can help you succeed with technology anywhere that people come together.</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 p-0">
                      <div class="solution-bg-img" :style="{ 'background-image': 'url(' + SolutionImage1 + ')' }"></div>
                    </div>
                  </div>
                </div>
                <div id="Education" class="tab-pane fade">
                  <div class="row">
                    <div class="col-md-6 p-0">
                      <div class="solution-single">
                        <div class="solution-single-inner">
                          <h4>Empowering educators & learners.</h4>
                          <hr>
                          <p>From a simple meeting room refresh to global digital transformation projects, we can show you how to leverage technology for competitive advantage in the marketplace.</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 p-0">
                      <div class="solution-bg-img" :style="{ 'background-image': 'url(' + SolutionImage2 + ')' }"></div>
                    </div>
                  </div>
                </div>
                <div id="Government" class="tab-pane fade">
                  <div class="row">
                    <div class="col-md-6 p-0">
                      <div class="solution-single">
                        <div class="solution-single-inner">
                          <h4>Proven Success & Reliability</h4>
                          <hr>
                          <p>We have proven experience working with local, state and national levels of government. We pride ourselves on offering the most cost-effective, thoroughly reseachered tenders possible, and on providing exceptional post-sales support. We service courts, councils, EOCs and defence agencies.</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 p-0">
                      <div class="solution-bg-img" :style="{ 'background-image': 'url(' + SolutionImage3 + ')' }"></div>
                    </div>
                  </div>
                </div>
                <div id="Healthcare" class="tab-pane fade">
                  <div class="row">
                    <div class="col-md-6 p-0">
                      <div class="solution-single">
                        <div class="solution-single-inner">
                          <h4>Enable Digital Healthcare & Drive Innovation.</h4>
                          <hr>
                          <p>Intuitive, integrated AV control solutions can assist healthcare staff with: managing patient records, accessing electronic medical libraries, monitoring emergency situations, and recording and disseminating information for teaching, research and consultation purposes.</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 p-0">
                      <div class="solution-bg-img" :style="{ 'background-image': 'url(' + SolutionImage4 + ')' }"></div>
                    </div>
                  </div>
                </div>
                <div id="VenueEntertainment" class="tab-pane fade">
                  <div class="row">
                    <div class="col-md-6 p-0">
                      <div class="solution-single">
                        <div class="solution-single-inner">
                          <h4>No Facility is Too Big.</h4>
                          <hr>
                          <p>Large or small, we have done them all, bringing a venue and their audience to life. Our solutions enhance guest experience at every interaction, supported by truly experienced integration & engineering resources.</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 p-0">
                      <div class="solution-bg-img" :style="{ 'background-image': 'url(' + SolutionImage5 + ')' }"></div>
                    </div>
                  </div>
                </div>
                <div id="Broadcast" class="tab-pane fade">
                  <div class="row">
                    <div class="col-md-6 p-0">
                      <div class="solution-single">
                        <div class="solution-single-inner">
                          <h4>Complete Studio Solutions</h4>
                          <hr>
                          <p>We provide technology solutions at every level of the production industry, helping our clients optimize digital broadcast in a rapidly advancing technology landscape.</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 p-0">
                      <div class="solution-bg-img" :style="{ 'background-image': 'url(' + SolutionImage6 + ')' }"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="application" class="space-60 app-section">
      <div class="container">
        <div class="heading abt-sec text-center">
          <div class="content">
            <h3>Our <span>Applications.</span></h3>
          </div>
        </div>
        <div class="additional-row">
          <div class="row justify-content-center">
            <div class="col-12 col-md-4">
              <div class="app-single text-center">
                <div class="app-single-img">
                  <img class="shape" src="../assets/img/application-icon-cover.png" alt="shape" width="145" height="190">
                  <img class="app-icon" src="../assets/img/vidtiser-icon.png">
                </div>
                <h4>Software AV Solution</h4>
                <p>Deployment | Control | Help-desk | Analytics. Empowering cutting edge Audio Visual software solutions. </p>
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div class="app-single text-center">
                <div class="app-single-img">
                  <img class="shape" src="../assets/img/application-icon-cover.png" alt="shape" width="145" height="190">
                  <img class="app-icon" src="../assets/img/vidtiser-icon.png">
                </div>
                <h4>Collaborative Spaces</h4>
                <p>Build face-to-face relationships with your global team without ever leaving the office!</p>
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div class="app-single text-center">
                <div class="app-single-img">
                  <img class="shape" src="../assets/img/application-icon-cover.png" alt="shape" width="145" height="190">
                  <img class="app-icon" src="../assets/img/vidtiser-icon.png">
                </div>
                <h4>Workplace Meeting Rooms</h4>
                <p>Enhance productivity and hold impromptu collaboration sessions with ease across your organization.</p>
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div class="app-single text-center">
                <div class="app-single-img">
                  <img class="shape" src="../assets/img/application-icon-cover.png" alt="shape" width="145" height="190">
                  <img class="app-icon" src="../assets/img/vidtiser-icon.png">
                </div>
                <h4>Lecture Rooms</h4>
                <p>Communicate across your organization with the click of a button.</p>
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div class="app-single text-center">
                <div class="app-single-img">
                  <img class="shape" src="../assets/img/application-icon-cover.png" alt="shape" width="145" height="190">
                  <img class="app-icon" src="../assets/img/vidtiser-icon.png">
                </div>
                <h4>Creative Art</h4>
                <p>Create visual art using advance audio visual components.</p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
    <section  v-if="windowwidth>400"  id="additional" class="space-60 add-section">
      <div class="container">
        <div class="heading abt-sec text-center">
          <div class="content">
            <h3>Additional <span>Capabilities</span></h3>
          </div>
        </div>
        <div  class="additional-row">
          <div class="row align-items-center">
            <div class="col-md-4">
              <div class="add-leftside">
                <p><strong><span>1</span> Audio Visual</strong></p>
                <p><strong><span>2</span> Smart Control</strong></p>
                <p><strong><span>3</span> Digital Signage</strong></p>
                <p><strong><span>4</span> Information Technology</strong></p>
                <p><strong><span>5</span> Command & Control</strong></p>
                <p><strong><span>6</span> Unified Collaboration</strong></p>
                <p class="add-note">Hover over the hotspots on the image to learn more about each application area.</p>
              </div>
            </div>

            <div class="col-md-8">
              <div class="add-rightside">
                <div class="add-rightside-inner">
                  <img src="../assets/img/AV2.jpg">
                  <div class="add-mob-dot" style="top: 30.2%; left: 16.9%;">
                    <div class="add-mob-top-link">
                      <a href="#dot1">1</a>
                      <div class="add-mob-hotspot-pulse"></div>
                    </div>
                  </div>
                  <div class="add-mob-dot" style="top: 42.1%; left: 58.8%;">
                    <div class="add-mob-top-link">
                      <a href="#dot2">2</a>
                      <div class="add-mob-hotspot-pulse"></div>
                    </div>
                  </div>
                  <div class="add-mob-dot" style="top: 45.9%; left: 82.4%;">
                    <div class="add-mob-top-link">
                      <a href="#dot3">3</a>
                      <div class="add-mob-hotspot-pulse"></div>
                    </div>
                  </div>
                  <div class="add-mob-dot" style="top: 87.8%; left: 60.3%;">
                    <div class="add-mob-top-link">
                      <a href="#dot4">4</a>
                      <div class="add-mob-hotspot-pulse"></div>
                    </div>
                  </div>
                  <div class="add-mob-dot" style="top: 80.1%; left: 74.8%;">
                    <div class="add-mob-top-link">
                      <a href="#dot5">5</a>
                      <div class="add-mob-hotspot-pulse"></div>
                    </div>
                  </div>
                  <div class="add-mob-dot" style="top: 73.5%; left: 86.7%;">
                    <div class="add-mob-top-link">
                      <a href="#dot6">6</a>
                      <div class="add-mob-hotspot-pulse"></div>
                    </div>
                  </div>
                </div>
                <div id="dot1" class="add-dot" style="top: 30.2%; left: 16.9%;">
                  <div class="add-top-link">
                    <a href="#">1</a>
                    <div class="add-hotspot-pulse"></div>
                  </div>
                  <div class="add-top-tooltip">
                    <p>
                      <b>Audio Visual </b>
                      <a href="#">
                        <img src="../assets/img/AV3.jpg" alt="Audio Visual">
                      </a>
                    </p>
                  </div>
                </div>
                <div id="dot2" class="add-dot" style="top: 39.1%; left: 57.8%;">
                  <div class="add-top-link">
                    <a href="#">2</a>
                    <div class="add-hotspot-pulse"></div>
                  </div>
                  <div class="add-top-tooltip">
                    <p>
                      <b>Smart Control</b>
                      <a href="#">
                        <img src="../assets/img/extroncontrol.jpg" alt="Smart Control">
                      </a>
                    </p>
                  </div>
                </div>
                <div id="dot3" class="add-dot" style="top: 19.9%; left: 92.4%;">
                  <div class="add-top-link">
                    <a href="#">3</a>
                    <div class="add-hotspot-pulse"></div>
                  </div>
                  <div class="add-top-tooltip">
                    <p>
                      <b>Digital Signage</b>
                      <a href="#">
                        <img src="../assets/img/LED_1.jpg" alt="">
                      </a>
                    </p>
                  </div>
                </div>
                <div id="dot4" class="add-dot" style="top: 68.8%; left: 10.3%;">
                  <div class="add-top-link">
                    <a href="#">4</a>
                    <div class="add-hotspot-pulse"></div>
                  </div>
                  <div class="add-top-tooltip">
                    <p>
                      <b>Information Technology</b>
                      <a href="#">
                        <img src="../assets/img/Information_Technology_banner.jpg" alt="">
                      </a>
                    </p>
                  </div>
                </div>
                <div id="dot5" class="add-dot" style="top: 58.1%; left: 37.8%;">
                  <div class="add-top-link">
                    <a href="#">5</a>
                    <div class="add-hotspot-pulse"></div>
                  </div>
                  <div class="add-top-tooltip">
                    <p>
                      <b>Command & Control</b>
                      <a href="#">
                        <img src="../assets/img/CommandLED.jpg" alt="Command & Control">
                      </a>
                    </p>
                  </div>
                </div>
                <div id="dot6" class="add-dot" style="top: 64.5%; left: 85.7%;">
                  <div class="add-top-link">
                    <a href="#">6</a>
                    <div class="add-hotspot-pulse"></div>
                  </div>
                  <div class="add-top-tooltip">
                    <p>
                      <b>Unified Collaboration</b>
                      <a href="#">
                        <img src="../assets/img/Microsoft-Surface-Hub-2-4K-Interactive-Whiteboard-02.jpg" alt="Unified Collaboration">
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


<vendors></vendors>

  </div>
</template>

<script>




import { event } from 'vue-gtag'
import Vendors from "./ourvendors";
export default {
  name: 'AV',
  components: {Vendors},
  data() {
    return {
      windowwidth: window.innerWidth,
      SolutionImage1:require('../assets/img/metago.jpg'),
      SolutionImage2:require('../assets/img/auditorium.jpg'),
      SolutionImage3:require('../assets/img/govermentav4.jpg'),
      SolutionImage4:require('../assets/img/healthcare1.jpg'),
      SolutionImage5:require('../assets/img/Yagan_Square.jpg'),
      SolutionImage6:require('../assets/img/born-in-broadcast.jpg'),
    }
  },
  mounted () {
    event('AV', { method: 'Google' })
  },

}
</script>

<style scoped>

@import url("../assets/css/mainservice.css");
#banner {
  background-attachment: scroll,							fixed;
  background-color: #666;
  background-image: url("../assets/img/overlay.png"), url("../assets/img/AV4.jpg");
  background-position:bottom;
  background-repeat: repeat,							no-repeat;
  background-size: auto,							cover;
  color: #fff;
  padding: 12em 0 20em 0;
  text-align: center;
}
.box .image.featured img{

  }
@media screen and (max-width: 480px){
  .box .image.featured img {
    border-radius: 0 !important;
    height: 220px;
  }
}

.audio-banner h2 {
  font-size: 3.5em;
  line-height: 1em;
  margin: 0 0 .5em;
  padding: 0;
  color: #fff;
  font-family: inherit;
  font-weight: 500;
  letter-spacing: -.035em;
}
.audio-banner p {
  font-size: 1.4em;
  margin-bottom: 0;
  color: #fff;
  line-height: 1.7;
  font-family: Amiri,serif !important;
}
.space-60 {
  padding: 60px 0;
}
.heading {
  display: block;
  width: 100%;
}
.abt-sec .content {
  width: 80%;
  margin: 0 auto;
}
.abt-sec h3 {
  font-size: 42px;
  margin-bottom: 55px;
  color: #000;
  position: relative;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  letter-spacing: -.025em;
}
.abt-sec span {
  font-size: 54px;
  color: #05a4db;
}
.add-section {
  background: #f6f6f6;
}
.add-leftside p {
  font-size: 18px;
  color: #000;
  letter-spacing: -.025em;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  margin-bottom: 20px;
}
.add-leftside p.add-note {
  font-size: 21px;
  font-family: Poppins-Regular;
  line-height: 1.2;
  color: #05a4db;
  font-weight: 400;
  margin: 0px 0px 0px 0px;
  padding: 10px 0px 0px 0px;
}
.add-leftside p strong span {
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #05a4db;
  color: #fff;
  line-height: 25px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  margin-right: 5px;
  top: -1px;
  position: relative;
}
.add-rightside {
  position: relative;
  margin-left: 70px;
}
.add-rightside-inner {
  position: relative;
}
.add-rightside > img, .add-rightside-inner > img {
  width: 100%;
}
.add-dot, .add-mob-dot {
  position: absolute;
  box-shadow: 0px 0px 20px -2px rgba(0,0,0,0.2);
}
.add-mob-top-link a, .add-top-link a {
  width: 25px;
  height: 25px;
  background: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  font-weight: 600;
  font-size: 15px;
  z-index: 2;
  position: relative;
}
.add-top-link a:hover, .add-mob-top-link a:hover  {
  text-decoration: none;
  color: #000;
}
.add-hotspot-pulse, .add-mob-hotspot-pulse {
  display: block;
  background: #fff;
  border-radius: 100px;
  height: 50px;
  width: 50px;
  position: absolute;
  -webkit-animation: av_pulsate 2s ease-out infinite;
  animation: av_pulsate 2s linear infinite;
  opacity: 0;
  top: -12.5px;
  left: -12.5px;
  z-index: 1;
}
@-webkit-keyframes av_pulsate {
  0% {
    -webkit-transform:scale(0.1);
    opacity:0.0
  }
  50% {
    opacity:0.7
  }
  100% {
    -webkit-transform:scale(1);
    opacity:0.0
  }
}
@keyframes av_pulsate {
  0% {
    transform:scale(0.1);
    opacity:0.0
  }
  50% {
    opacity:0.7
  }
  100% {
    transform:scale(1);
    opacity:0.0
  }
}
.add-top-link, .add-mob-top-link {
  display: inline-block;
  position: relative;
}
.add-top-tooltip {
  width: 350px;
  position: absolute;
  top: 50%;
  right: 100%;
  background: #fff;
  padding: 20px 15px 15px 15px;
  border-radius: 5px;
  text-align: center;
  box-shadow: 0px 0px 40px -2px rgba(0,0,0,0.2);
  transform: translate(-15px,-50%) translateX(-1rem);
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
  z-index: 999;
}
.add-dot:hover .add-top-tooltip {
  transform: translate(-15px,-50%) translateX(0);
  opacity: 1;
  visibility: visible;
  transition: 0.5s;
}
.add-top-tooltip p {
  font-size: 16px;
  font-family: Poppins-Regular;
  line-height: 20px;
  color: #777;
  font-weight: 400;
  margin: 0px 0px 0px 0px;
  padding: 0px;
  display: block;
}
.add-top-tooltip p b {
  color: #05a4db;
  font-family: Poppins-Regular,sans-serif !important;
  font-size: 18px;
  letter-spacing: -.025em;
  font-weight: 600;
  margin-bottom: 0px;
}
.add-top-tooltip a {
  display: block;
  margin-top: 15px;
}
.add-top-tooltip a img {
  width: 100%;
}
.add-top-tooltip::before {
  content: "";
  position: absolute;
  right: 0px;
  top: 50%;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-left: 15px solid #fff;
  border-bottom: 10px solid transparent;
  transform: translate(60%, -50%);
}
.service-single h4, .app-single h4, .solution-single-inner h4 {
  font-size: 24px;
  color: #000;
  letter-spacing: 0px;
  font-family: inherit;
  font-weight: 500;
  line-height: 25px;
}
hr {
  margin-top: 1rem!important;
  margin-bottom: 1rem!important;
  border-top: 3px solid #040300!important;
  max-width: 10%!important;

}
.service-single h4 {
margin: 35px 0px 10px 0px;
}
.app-single h4 {
margin: 15px 0px 10px 0px;
}
.service-single p, .app-single p, .solution-single-inner p {
font-family: Poppins-Regular;
font-size: 16px;
line-height: 1.7;
color: #666;
margin: 0px;
}
.service-single img {
height: 100px;
width: auto;
}
.service-single {
padding: 40px 20px 40px 20px;
background: transparent;
transition: 0.5s;
position: relative;
border-radius: 10px;
height: calc(100% - 30px);
margin-bottom: 30px;
}
.service-section {
background: #fff;
}
.service-single:hover {
background: #fff;
box-shadow: 0px 0px 30px -5px rgba(0,0,0,0.2);
transition: 0.5s;
}
.service-section .additional-row {
margin-bottom: -30px;
}
.service-single::before {
content: "";
position: absolute;
top: 0px;
left: 50%;
transform: translate(-50%,0%);
width: 50px;
height: 4px;
border-radius: 0px 0px 5px 5px;
background: transparent;
transition: 0.5s;
}
.service-single:hover::before {
background: #05a4db;
transition: 0.5s;
}
.app-section {
background: #fff;
}
.app-single {
padding: 50px 25px 40px 25px;
background: #fff;
box-shadow: 0px 0px 20px -3px rgba(0, 0, 0, 0.15);
border-radius: 10px;
height: calc(100% - 105px);
margin: 55px 0px 50px 0px;
border: 1px solid transparent;
transition: 0.3s;
position: relative;
}
.app-single:hover {
box-shadow: 0px 0px 20px -3px rgba(0, 0, 0, 0.2);
border: 1px solid #05a4db;
transition: 0.3s;
}
.app-section .additional-row {
margin-bottom: -50px;
}
.app-single-img {
display: inline-block;
position: relative;
}
.app-single-img img {
-webkit-transition: all .4s ease-out 0s;
-moz-transition: all .4s ease-out 0s;
-ms-transition: all .4s ease-out 0s;
-o-transition: all .4s ease-out 0s;
transition: all .4s ease-out 0s;
}
.app-single-img .shape {
max-width: 70px;
height: auto;
}
.app-single-img img.app-icon {
position: absolute;
top: 50%;
left: 50%;
-webkit-transform: translate(-50%,0%);
-moz-transform: translate(-50%,0%);
-ms-transform: translate(-50%,0%);
-o-transform: translate(-50%,0%);
transform: translate(-50%,0%);
height: 30px;
width: auto;
}
.app-single-img {
position: absolute;
top: 0px;
left: 50%;
transform: translate(-50%,-61.5%);
}
.app-single:hover .app-single-img .shape {
-webkit-transform: scale(1.1);
-moz-transform: scale(1.1);
-ms-transform: scale(1.1);
-o-transform: scale(1.1);
transform: scale(1.1);
}
.solutions-section {
background: #f6f6f6;
}
.solutions-section .abt-sec h3 {
margin-bottom: 25px;
}
.solutions-section .abt-sec p {
font-family: Poppins-Regular;
font-size: 21px;
line-height: 1.7;
color: #666;
margin: 0px 0px 55px 0px;
}
.solutions-section .additional-row {
padding: 0px 15px;
}
.solutions-section .additional-row {
padding: 0px 15px;
}
.solution-tabs ul li {
display: block;
width: 100%;
}
.solution-tabs ul li a {
background: #f6f6f6;
width: 100%;
display: block;
padding: 20px 30px;
text-decoration: none;
font-size: 14px;
color: #000;
letter-spacing: 1px;
font-family: inherit;
font-weight: 500;
line-height: 20px;
text-transform: uppercase;
border-left: 5px solid transparent;
transition: 0.5s;
}
.solution-tabs ul li a:hover {;
border-left: 5px solid #000;
background: #e5e6e7;
transition: 0.5s;
}
.solution-tabs ul li a.active {
background: #fff;
font-weight: 600;
  border-left: 5px solid #07a5db;
transition: 0.5s;
}
.solutions-section .tab-content {
padding: 0px 15px;
height: 100%;
}
.solutions-section .tab-content .tab-pane, .solutions-section .tab-content .tab-pane .row, .solutions-section .tab-content .tab-pane .row .col-md-6 {
height: 100%;
}
.solution-single {
height: 100%;
background: #fff;
padding: 50px;
display: flex;
align-items: center;
}
.solution-bg-img {
height: 100%;
background-size: cover;
background-repeat: no-repeat;
background-position: center;
}


@media (max-width: 767.98px) {
.abt-sec h3 {
font-size: 32px;
margin-bottom: 35px;
}
.abt-sec span {
font-size: 44px;
}
.audio-banner {
padding: 12em 0 6em;
}
.audio-banner h2 {
font-size: 2.25em;
}
.audio-banner p {
font-size: 1.25em;
}
.space-60 {
padding: 40px 0;
}
.add-leftside p {
font-size: 16px;
}
.add-leftside p.add-note {
font-size: 18px;
padding: 10px 0px 40px 0px;
}
.add-rightside {
margin-left: 0px;
}
.service-single h4, .app-single h4, .solution-single-inner h4 {
font-size: 20px;
line-height: 20px;
}
.service-single h4 {
margin: 25px 0px 10px 0px;
}
.app-single h4 {
margin: 5px 0px 10px 0px;
}
.service-single p, .app-single p, .solution-single-inner p {
font-size: 14px;
line-height: 1.4;
}
.service-single {
padding: 40px 15px 40px 15px;
background: #fff;
height: calc(100% - 15px);
margin-bottom: 15px;
box-shadow: 0px 0px 30px -5px rgba(0,0,0,0.2);
}
.service-single::before {
background: #05a4db;
}
.service-section .additional-row {
margin-bottom: -15px;
}
.app-section .additional-row {
margin-bottom: -20px;
}
.add-hotspot-pulse {
height: 40px;
width: 40px;
top: -10px;
left: -10px;
display: none;
}
.add-mob-hotspot-pulse {
height: 40px;
width: 40px;
top: -10px;
left: -10px;
}
.add-dot {
position: relative;
box-shadow: none;
top: auto !important;
left: auto !important;
padding-left: 60px;
margin-top: -20px;
padding-top: 40px;
}
.add-top-link a {
width: 40px;
height: 40px;
font-size: 16px;
border: 1px solid #ccc;
color: #666;
font-weight: 400;
}
.add-mob-top-link a {
width: 20px;
height: 20px;
font-size: 12px;
}
.add-top-tooltip {
position: relative;
width: 100%;
transform: none;
opacity: 1;
visibility: visible;
padding: 15px 10px 10px 10px;
top: auto;
right: auto;
background: #f6f6f6;
box-shadow: -10px 0px 20px -2px rgba(0,0,0,0.1);
}
.add-top-link {
position: absolute;
left: 0px;
}
.add-top-tooltip::before {
right: auto;
top: 15px;
border-right: 8px solid #f6f6f6;
transform: translate(-100%, 0%);
left: 0px;
border-left: transparent;
border-top: 5px solid transparent;
border-bottom: 5px solid transparent;
}
.add-top-tooltip p b {
font-size: 14px;
}
.add-top-tooltip p {
font-size: 12px;
text-align: left;
}
.add-top-tooltip a img {
width: 200px;
}
.add-dot:hover .add-top-tooltip {
transform: none;
}
.service-single img {
height: 80px;
}
.app-single {
padding: 0px 0px 0px 70px;
height: calc(100% - 30px);
margin: 0px 0px 30px 0px;
box-shadow: none;
text-align: left !important;
min-height: 50px;
}
.app-single-img {
top: 50%;
left: 0px;
transform: translate(0%,-50%);
}
.solutions-section .abt-sec p {
font-size: 16px;
line-height: 1.5;
margin: 0px 0px 35px 0px;
}
.abt-sec .content {
width: 100%;
}
.solution-tabs ul li {
display: inline-block;
width: auto;
margin: 2px;
}
.solution-tabs ul li a {
width: auto;
display: inline-block;
padding: 8px 10px;
font-size: 12px;
letter-spacing: 0.5px;
line-height: 15px;
text-transform: capitalize;
border-left: none;
transition: 0.5s;
font-weight: 600;
}
.solution-tabs ul li a.active {
background: #05a4db;
border-left: none;
color: #fff;
text-shadow: 1px 1px 2px rgba(0,0,0,0.2);
}
.solution-tabs ul {
justify-content: center;
}
.solution-tabs ul li a:hover {
border-left: none;
}
.solution-single {
height: auto;
padding: 20px;
}
.solutions-section .tab-content .tab-pane, .solutions-section .tab-content .tab-pane .row, .solutions-section .tab-content .tab-pane .row .col-md-6 {
height: auto;
}
.solutions-section .tab-content {
padding: 20px 15px 0px 15px;
height: auto;
}
.solution-bg-img {
height: 250px;
}
.app-single-img .shape {
max-width: 50px;
}
.app-single-img img.app-icon {
height: 20px;
}
.app-single:hover {
box-shadow: none;
border: 1px solid transparent;
transition: 0.3s;
}
}

@media (min-width: 768px) {
.add-mob-dot {
display: none;
}
}
</style>
