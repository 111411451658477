<template>
  <div>
    <section id="banner">

      <div id="videoBlock">


        <video id="Player"
               autoplay
               loop
               muted >

          <source
                  src="../assets/img/ellenbrookarts.mp4"
                  type="video/mp4">
          Your browser does not support the video tag.
        </video>
      </div>
      <div id="videoMessage">
        <h2>Ellenbrook Art Awards</h2>
        <div class="tags">
          <a>V-Wall LED Video Wall</a>
          <a >V-Signage</a>
          <a >vCore</a>
        </div>

      </div>
    </section>
   <div></div>
   <div class="row mh1">
     <div class="col-md">
       <wrapper1 color="black"  title="V-Wall VPQ" Subtitle="Indoor LED Video Wall" :thimg="img1" info="V-Wall 1.8 pixel pitch allowed the campaign to display vibrant bright artwork to the public in the Ellenbrook mall, bringing the community closer to the local art  "></wrapper1>
   </div>
     <div   class="col-md">
       <wrapper1  color="white" title="V-Signage" Subtitle="Vidtiser Digital Signage Solution" :thimg="img2" info="Using V-signage we curated the artwork in a way that allows the LED video wall to engage with the public with clarity and precision."></wrapper1>
     </div>
     <div class="col-md">
       <wrapper1  color="white" title="vCore"  Subtitle="Web application" :thimg="img3" info="With V-touch and vCore, Vidtiser designed a voting platform that engaged with the public to help them connect with the community event. creating an element to the campaign that reached out to the public with a connection to the local art.  " ></wrapper1>
     </div>
   </div>
       <section style="background-color: #dbdee3" id="about" class="space-30 abt-sec background1">
         <div class="container-fluid">
           <div  class="row">
             <div class="col-lg-5" style="margin-right:30px ">
               <div  style="margin-top: 150px;padding-bottom: 50px;" class="content">
                 <h3><span>Summary</span></h3>
                 <p>There's nothing more rewarding for Vidtiser to see the results of a community organisation like Ellenbrook Arts gaining an increase in their audience engagement.

                     Utilizing one of the latest released indoor video wall we encourage the participation of Ellenbrook residents and community to vote for their favourite artwork during 2019 Ellenbrook Art Award.

                     Vidtiser designed custom software that played through interactive touch screen as well as an online platform.

                     A range of business such as Prime Project, LWP, Metropolitan Framing and the City of Swan played important roles in the success of the award.

                     Having digital display certainly created a trail of thoughts and excitement, encourages the community to visit and participate at the gallery.</p>
               </div>
             </div>
             <!-- /.col-md-6 -->

          <div  style="margin-top: 150px" class="col-lg-6">
            <img  class="imgstyle" src="../assets/img/artawardweb.jpg" alt="">
          </div>
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container -->
    </section>
  </div>
</template>

<script>
import Wrapper from "./wrapper";
import Wrapper1 from "./wrapper1";
export default {
  name: 'blog_1',
    data () {
        return {
            img1: require('../assets/img/vpq.png'),
            img2: require('../assets/img/retailsign.jpg'),
            img3: require('../assets/img/vtouch.jpg')
        }
    },
  components: {Wrapper1},
  methods: {
  },
  created() {

  },
  mounted () {

    $('.point-content .close').on('click', function(e) {

      $(this).parent().removeClass('active');

    })

    $('.point').on('click', function(e) {
      e.stopPropagation();

      $(this).next().addClass('active');


    })
  }
}
</script>

<style lang="scss" >
  #videoBlock {width: 100%; height: 100%;  display: flex;  justify-content: center; align-items: center;}
  #videoMessage {width: 100%; height:100%; position: absolute; top: 0; left: 0;position: inherit}
  #videoMessage *{padding:0.4em; margin:0}
  #videoMessage { color:white;z-index:99 ; display: none;}
  video {
    height: 100%;
    width: 100%;
  }
  .imgstyle{

    max-width: 1100px;
  }
  @media screen and (max-width: 1680px){
    #banner {padding: 0em 0em 0em 0em}
  }

  @media screen and (max-width: 480px){


    #videoMessage{ display: none;}
  }

  .mh1 div {
    height: 80vh; /* 30% of viewport height*/
  }
.bannerbac{
  background-image:   linear-gradient(
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
  ),url('../../static/yagan2.jpg');
}
  $red: #FA4647;
  $light-bg: #fefefe;
  $yellow: #f3ce2b;
  $blue: #046BF8;
  #banner {
    background-attachment: scroll,							fixed;
    background-color: #666;
    background-position: top left,						center center;
    background-repeat: repeat,							no-repeat;
    background-size: auto,							cover;
    color: #fff;
background-image: url("../assets/img/videowall1.jpg");
    text-align: center;

  }

  .container1 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }

  .right {
    width: 364px;
    text-align: center;
    align-items: flex-end;
    display: flex;
    justify-content: center;
  }

  .item {
    padding: 15px 15px 0;
    box-shadow: 0 40px 70px 0 rgba(0, 0, 0, 0.22), 0px 27px 24px 0 rgba(0, 0, 0, 0.2);
    background: $light-bg;
    width: 600px;
    height: 450px;
    border-radius: 4px;
    display: flex;
  .item-image {
    display: inline-block;
    position: relative;
  img {
    display: block;
    max-width: 100%;
  }
  }
  .image-point {
    position: absolute;
  .point {
  &:before {
     transform: translate(-50%, -50%) rotate(45deg);
   }
  }
  .point {
    position: relative;
  //  display: inline-block;
    cursor: pointer;
    height: 18px;
    width: 18px;
    background: $blue;
    border-radius: 100%;
  //animation-fill-mode: forwards;
    animation-name: pulseOut;
    animation-duration: .5s;
    animation-iteration-count: 1;
  &:hover {
     animation-fill-mode: forwards;
     animation-name: pulseIn;
     animation-duration: .5s;
     animation-iteration-count: 1;
   }
  &:before {
     transition: all 0.5s;
     color: white;
     content: "+";
     font-size: 12px;
     position: absolute;
     transform: translate(-50%, -50%);
     top: 50%;
     display: inline;
     left: 50%;
     transform-origin: 50% 50%;
   }
  }
  }
  .item-description {
    padding-top: 52px;
  small {
    text-transform: uppercase;
    font-size: 10px;
    color: #cccccc;
  }
  }
  .description-price {
    font-size: 26px;
    color: $blue;
    position: relative;
  &:after {
     position: absolute;
     content: attr(data-currency);
     font-size: 10px;
   }
  }
  .description-title {
    margin-top: 0;
    position: relative;
    z-index: 5;
    font-size: 34px;
    margin-bottom: 10px;
  &:before {
     content: "";
     height: 8px;
     position: absolute;
     z-index: -1;
     left: 3px;
     width: 60%;
     bottom: 12px;
     background: $yellow;
   }
  }
  }

  //Pointer
    .point-content {
      display: none;
      transition: all 0.5s;
      visibility: hidden;
      opacity: 0;
      top: 0;
      position: absolute;
      max-width: 400px;
      min-height: 200px;
      background: white;
      display: flex;
      box-shadow: 0 40px 70px 0 rgba(0, 0, 0, 0.22), 0px 27px 24px 0 rgba(0, 0, 0, 0.2);
  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }
  &.active {
     top: 20px;
     visibility: visible;
     opacity: 1;
     z-index: 99;
   }
  .point-details {
    padding: 15px;
    text-align: left;
    font-size: 12px;
  }
  .point-image {
    padding: 10px 0;
    width: 500px;
  img {}
  }
  }

  //Button
    .btn {
      display: inline-block;
      background: $blue;
      padding: 15px 20px;
      color: white;
      border-radius: 4px;
      cursor: pointer;
      transition: all 0.5s;
      bottom: 10;
      text-transform: uppercase;
  &:hover {
     transform: translateY(-2px);
   //box-shadow: 0 40px 70px 0 rgba(0, 0, 0, 0.22),0px 27px 24px 0 rgba(0, 0, 0, 0.2);
     box-shadow: 0 25px 35px 0 rgba(0, 0, 0, 0.2);
   }
  }



  @keyframes pulseIn {
    from {

      box-shadow: transparent 0 0 0 0;
    }
    to {
      box-shadow: //0 0 0 5px rgba(108, 99, 255,.40),
      0 0 0 5px rgba(4, 107, 248, .30), 0 0 0 10px rgba(4, 107, 248, .20), 0 0 0 15px rgba(4, 107, 248, .10);
    }
  }

  @keyframes pulseOut {
    from {
      box-shadow: //0 0 0 5px rgba(108, 99, 255,.40),
      0 0 0 5px rgba(4, 107, 248, .30), 0 0 0 10px rgba(4, 107, 248, .20), 0 0 0 15px rgba(4, 107, 248, .10);
    }
    to {
      box-shadow: transparent 0 0 0 0;
    }
  }

</style>
