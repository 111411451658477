<template>
  <div class="landing">
  <section id="banner">
    <h2>V Wall</h2>
    <p>Vidtisers LED Video Walls Solutions</p>
  </section>

  <section id="main" class="container">

    <section class="box special ">
      <header class="major">
        <h2>Vidtiser's GO PLEX Indoor Video Walls</h2>
        <p>Vidtiser's GO PLEX Indoor range is a cost-effective thin and vibrant indoor LED video.This range is packed with loads of features like the smart display control system, allowing its high-capacity receiving cards that bear over 2X data loading capacity than conventional ones and support smarter display functions like 3D display, and effective mapping function. </p>
      </header>


      <img  style="width: 50%" src="../assets/img/tmax.png"/>

      <a href="#contact100" style="display: block" class="btn-xl scroll ">contact us now for more information</a>

    </section>

    <section class="box special features">
      <div class="features-row"></div>
      <section class="box special features">
       <!--  <div class="features-row">
         <agile :nav-buttons="false" :autoplay-speed="5000" :speed="2500" fade="fade" pause-on-hover="pause-on-hover" pause-on-dots-hover="pause-on-dots-hover" autoplay="autoplay"><img class="slide" src="../assets/img/metago.jpg"/><img class="slide" src="../assets/img/metago1.jpg"/><img class="slide" src="../assets/img/metago2.jpg"/><img class="slide" src="../assets/img/metago3.jpg"><img class="slide" src="../assets/img/metago4.jpg"/></agile>
          <h3>METAGO</h3>

          <p>METAGO is an integrated LED display terminal infused with functions like wireless mirroring,
            video conferencing and interactive writing to make all presentations brilliant and impressive.
            It can be well applied in large conference rooms, lecture halls, multi-function rooms, multi-media rooms, studios, and any other needed occasions to greatly increase meeting collaboration efficiency.</p>

        </div>-->
        <br>
      </section>
      <div class="features-row">
        <section>
          <agile class="image featured" :nav-buttons="false" :autoplay-speed="7000" :speed="2500" fade="fade" pause-on-hover="pause-on-hover" pause-on-dots-hover="pause-on-dots-hover" autoplay="autoplay"><img class="slide" src="../assets/img/outdoorled_1.jpg"/><img class="slide" src="../assets/img/LED_outdoor_1.webp"/><img class="slide" src="../assets/img/LED_outdoor_3.jpeg"/></agile>

          <h3>GO-PLEX Outdoor</h3>
          <p>LED Displays can be one of the smartest investments you make in your business/churches/schools and community venues. Before your organization decides on an LED display, it’s important to know what your options are in order to make an informed decision and what factors you should consider for your LED Digital Display purchase. We here at Vidtiser can design and shape a system to suit your needs</p>
        </section>
        <section>
          <agile class="image featured" :nav-buttons="false" :autoplay-speed="10000" :speed="2500" fade="fade" pause-on-hover="pause-on-hover" pause-on-dots-hover="pause-on-dots-hover" autoplay="autoplay"><img class="slide" src="../assets/img/LED_MEGARA_2.jpg"/><img class="slide" src="../assets/img/led-meeting-room-solution-1170x782.jpg"/><img class="slide" src="../assets/img/Indoor_LED6.jpg"/></agile>

          <h3>GO-PLEX Indoor</h3>
          <p>LED video wall displays make use of diverse technologies such as multimedia technology, computer technique, video technology and many more. Vidtiser Indoor series LED offers vibrant sharp colours, energy-efficient and long-lasting panels. Our LED products are reliable and can achieve resolution up to 8K, bringing to life your project.</p>
        </section>
      </div>
      <div class="features-row">
        <section>
          <agile class="image featured" :nav-buttons="false" :autoplay-speed="8000" :speed="2500" fade="fade" pause-on-hover="pause-on-hover" pause-on-dots-hover="pause-on-dots-hover" autoplay="autoplay"><img class="slide" src="../assets/img/transperant_LED.jpg"/><img class="slide" src="../assets/img/transperant_LED_1.jpg"/><img class="slide" src="../assets/img/transperant_LED_2.jpg"/></agile>

          <h3>GO-PLEX Transparent</h3>
          <p>Whether you need to create a custom Transparent LED display or need a portable transparent led poster Screen, Transparent LED can provide a display solution for your installation. Transparent LED displays offer indoor and outdoor use options and can be supplied as flat (Glass LED Display), curved or cylindrical transparent LED units. With unique versatility, our systems can be integrated to create cost-effective, transparent LED displays that provide your customers with powerful information</p>
        </section>
        <section>
          <agile class="image featured" :nav-buttons="false" :autoplay-speed="10000" :speed="2500" fade="fade" pause-on-hover="pause-on-hover" pause-on-dots-hover="pause-on-dots-hover" autoplay="autoplay"><img class="slide" src="../assets/img/20210627-07861.jpg"/><img class="slide" src="../assets/img/20210627-07623.jpg"><img class="slide" src="../assets/img/GOPLEX-CHURCH-3.jpg"/><img class="slide" src="../assets/img/GOPLEX-CHURCH-4.jpg"/></agile>

          <h3>GO-PLEX House Of Worship</h3>
          <p>We offer LED video walls in a variety of sizes to fit any space and any budget. Our team of expert technicians will work with you to design a custom LED video wall that meets your church's unique needs and aesthetic preferences.</p>
          <p>Our state-of-the-art LED video walls provide an immersive visual experience that will captivate and engage your congregation. With vibrant colors, high-resolution displays, and customizable sizing options, our LED video walls are the perfect addition to any church.</p>
</section>
      </div>
      <div class="features-row">
      <section>
        <agile class="image featured" :nav-buttons="false" :autoplay-speed="10000" :speed="2500" fade="fade" pause-on-hover="pause-on-hover" pause-on-dots-hover="pause-on-dots-hover" autoplay="autoplay"><img class="slide" src="../assets/img/billboard.jpeg"/><img class="slide" src="../assets/img/Outdoor_LED4.jpg"/><img class="slide" src="../assets/img/Outdoor_LED2.jpg"/></agile>

        <h3>LED GO-PLEX Billboard</h3>
        <p>Corporate branding initiatives benefit from artistic signage that communicates a company’s philosophy and brand.</p>
        <p>Many artistic video walls are created as semi-permanent installations with the idea that they will remain in place for the foreseeable future, perhaps the next 3-15 years. But there is also significant demand for temporary art video walls.  </p>
      </section>
      <section>
        <agile class="image featured" :nav-buttons="false" :autoplay-speed="10000" :speed="2500" fade="fade" pause-on-hover="pause-on-hover" pause-on-dots-hover="pause-on-dots-hover" autoplay="autoplay"><img class="slide" src="../assets/img/sport-img-soccer-wicon.jpg"/><img class="slide" src="../assets/img/led-hero-18-sycamore.jpg"/></agile>

        <h3>LED GO-PLEX Scoreboards</h3>
        <p>Electronic scoreboards increase the fairness and atmosphere surrounding gameplay</p>
        <p>for students, staff and parents, encouraging participation and the sporting community. The user-friendly components can be tailored to different sports, ranging from basketball, rugby or cricket to multi-sport, and the customisation allows buyers to tailor their scoreboards with logos LEDs in their school colours</p>
      </section>
      </div>
      <section class="box special features">
        <div class="features-row">
          <agile :nav-buttons="false" :autoplay-speed="5000" :speed="2500" fade="fade" pause-on-hover="pause-on-hover" pause-on-dots-hover="pause-on-dots-hover" autoplay="autoplay"><img class="slide" src="../assets/img/LED_MEGARA_1.jpg"/><img class="slide" src="../assets/img/LED_MEGARA_3.jpg"/><img class="slide" src="../assets/img/LED_1.jpg"/><img class="slide" src="../assets/img/retail-led.jpg"/><img class="slide" src="../assets/img/LED_3.jpg"><img class="slide" src="../assets/img/LED_4.jpg"><img class="slide" src="../assets/img/LED_5.jpg"></agile>
        </div>
        <br>
        <div class="features-row"></div>
      </section>
    </section>



  </section>
  </div>
</template>

<script>
  import  VueAgile from "./lcdvideowall"
  import { event } from 'vue-gtag'
export default {
  name: 'V_wall',
  mounted () {
    event('Vwall', { method: 'Google' })
  },
  components: { agile: VueAgile}
}
</script>

<style scoped>

@import url("../assets/css/mainservice.css");
#banner {
  background-attachment: scroll,							fixed;
  background-color: #666;
  background-image: url("../assets/img/overlay.png"), url("../assets/img/Crystal-LED-5.jpg");
  background-position: top left,						center center;
  background-repeat: repeat,							no-repeat;
  background-size: auto,							cover;
  color: #fff;
  padding: 12em 0 20em 0;
  text-align: center;
}
  .mob{
    height: 375px !important;
  }

@media screen and (max-width: 480px){
  .mob{
    height: 150px !important;
  }
}
@media screen and (max-width: 480px){
  .box .image.featured img {
    border-radius: 0 !important;
    height: 220px;
  }
}
</style>
