<template>
  <div>
  <section class="banner bannerbac">
    <div class="container">
      <div class="row">
        <div class="banner_content text-center">
          <h1 class="main-title white-clr">Yagan Square</h1>
          <div class="tags">
            <a>Qflex</a>
            <a >LED</a>
            <a >Network Audio</a>
          </div>
          <p class="white-clr">Vidtiser was contacted to do the Tower sound and the live Video system</p>
        </div>
      </div>
      <!-- /.row -->
    </div>
    <!-- /.container -->
  </section>
<div></div>
<div class="row mh1">
  <div class="col-md">
    <wrapper1 title="Speakers" Subtitle="Qflex audio" :thimg="img1" info="Vidtiser used two Qflex 48 to spread the sound over the walkway and to achieve the same decibel from 5 meters to 60 meters"></wrapper1>
</div>
  <div   class="col-md">
    <wrapper1 title="Audio Transport" Subtitle="Dante audio" :thimg="img2" info="Dante audio was used to deliver the sound to the tower, this was achieved through fibre and the Klarkteknik dsp"></wrapper1>
  </div>
  <div class="col-md">
    <wrapper1  title="Video Distribution"  Subtitle="Blackmagic" :thimg="img3" info="Yagan square has many sdi inputs around the venue to allow for different types of functions. To tie this together Vidtiser used Blackmagic Broadcast gear to allow the operators to see and switch to the multiple inputs live" ></wrapper1>
  </div>
</div>
    <section id="about" class="space-60 abt-sec">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-6">
            <div class="content">
              <h3><span>Summary</span></h3>
              <p>Vidtiser was contracted to supply and install the tower sound, the contract required the sound to throw 80 meters from the tower up through the walk way and it needed to have consistent db levels. To do this Vidtiser used the advanced feature of Qflex speakers to steer the audio so the correct volume could be the same from 5 meters to 60 meters.</p>
              <p>To achieve the best sound to the LED tower, Vidtiser used Dante audio over fibre to the tower which allowed the speakers to get very low latency network audio without any interference
              </p>
            </div>
          </div>
          <!-- /.col-md-6 -->

          <div class="col-lg-6">
            <img src="../assets/img/180322-Yagan-Featured-Post-Thumbnail.jpg" alt="">
          </div>
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container -->
    </section>
  </div>
</template>

<script>

import Wrapper1 from "./wrapper1";
export default {
  name: 'blog_1',
    data () {
        return {
            img1: require('../assets/img/180322-Yagan-Featured-Post-Thumbnail.jpg'),
            img2: require('../assets/img/audio.jpg'),
            img3: require('../assets/img/banner-capture.jpg')
        }
    },
  components: {Wrapper1},
  methods: {
  },
  created() {

  },
  mounted () {

    $('.point-content .close').on('click', function(e) {

      $(this).parent().removeClass('active');

    })

    $('.point').on('click', function(e) {
      e.stopPropagation();

      $(this).next().addClass('active');


    })
  }
}
</script>

<style lang="scss">
  .mh1 div {
    height: 80vh; /* 30% of viewport height*/
  }
.bannerbac{
  background-image:   linear-gradient(
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
  ),url('../../static/yagan2.jpg');
}
  $red: #FA4647;
  $light-bg: #fefefe;
  $yellow: #f3ce2b;
  $blue: #046BF8;


  .container1 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }

  .right {
    width: 364px;
    text-align: center;
    align-items: flex-end;
    display: flex;
    justify-content: center;
  }

  .item {
    padding: 15px 15px 0;
    box-shadow: 0 40px 70px 0 rgba(0, 0, 0, 0.22), 0px 27px 24px 0 rgba(0, 0, 0, 0.2);
    background: $light-bg;
    width: 600px;
    height: 450px;
    border-radius: 4px;
    display: flex;
  .item-image {
    display: inline-block;
    position: relative;
  img {
    display: block;
    max-width: 100%;
  }
  }
  .image-point {
    position: absolute;
  .point {
  &:before {
     transform: translate(-50%, -50%) rotate(45deg);
   }
  }
  .point {
    position: relative;
  //  display: inline-block;
    cursor: pointer;
    height: 18px;
    width: 18px;
    background: $blue;
    border-radius: 100%;
  //animation-fill-mode: forwards;
    animation-name: pulseOut;
    animation-duration: .5s;
    animation-iteration-count: 1;
  &:hover {
     animation-fill-mode: forwards;
     animation-name: pulseIn;
     animation-duration: .5s;
     animation-iteration-count: 1;
   }
  &:before {
     transition: all 0.5s;
     color: white;
     content: "+";
     font-size: 12px;
     position: absolute;
     transform: translate(-50%, -50%);
     top: 50%;
     display: inline;
     left: 50%;
     transform-origin: 50% 50%;
   }
  }
  }
  .item-description {
    padding-top: 52px;
  small {
    text-transform: uppercase;
    font-size: 10px;
    color: #cccccc;
  }
  }
  .description-price {
    font-size: 26px;
    color: $blue;
    position: relative;
  &:after {
     position: absolute;
     content: attr(data-currency);
     font-size: 10px;
   }
  }
  .description-title {
    margin-top: 0;
    position: relative;
    z-index: 5;
    font-size: 34px;
    margin-bottom: 10px;
  &:before {
     content: "";
     height: 8px;
     position: absolute;
     z-index: -1;
     left: 3px;
     width: 60%;
     bottom: 12px;
     background: $yellow;
   }
  }
  }

  //Pointer
    .point-content {
      display: none;
      transition: all 0.5s;
      visibility: hidden;
      opacity: 0;
      top: 0;
      position: absolute;
      max-width: 400px;
      min-height: 200px;
      background: white;
      display: flex;
      box-shadow: 0 40px 70px 0 rgba(0, 0, 0, 0.22), 0px 27px 24px 0 rgba(0, 0, 0, 0.2);
  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }
  &.active {
     top: 20px;
     visibility: visible;
     opacity: 1;
     z-index: 99;
   }
  .point-details {
    padding: 15px;
    text-align: left;
    font-size: 12px;
  }
  .point-image {
    padding: 10px 0;
    width: 500px;
  img {}
  }
  }

  //Button
    .btn {
      display: inline-block;
      background: $blue;
      padding: 15px 20px;
      color: white;
      border-radius: 4px;
      cursor: pointer;
      transition: all 0.5s;
      bottom: 10;
      text-transform: uppercase;
  &:hover {
     transform: translateY(-2px);
   //box-shadow: 0 40px 70px 0 rgba(0, 0, 0, 0.22),0px 27px 24px 0 rgba(0, 0, 0, 0.2);
     box-shadow: 0 25px 35px 0 rgba(0, 0, 0, 0.2);
   }
  }



  @keyframes pulseIn {
    from {

      box-shadow: transparent 0 0 0 0;
    }
    to {
      box-shadow: //0 0 0 5px rgba(108, 99, 255,.40),
      0 0 0 5px rgba(4, 107, 248, .30), 0 0 0 10px rgba(4, 107, 248, .20), 0 0 0 15px rgba(4, 107, 248, .10);
    }
  }

  @keyframes pulseOut {
    from {
      box-shadow: //0 0 0 5px rgba(108, 99, 255,.40),
      0 0 0 5px rgba(4, 107, 248, .30), 0 0 0 10px rgba(4, 107, 248, .20), 0 0 0 15px rgba(4, 107, 248, .10);
    }
    to {
      box-shadow: transparent 0 0 0 0;
    }
  }

</style>
